import React from "react";
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';

function privacyKO(props) {
  return (
    <Container>
      <Typography variant="h4" mb={4} gutterBottom>
        최종 사용자 사용권 계약
      </Typography>
      <Typography paragraph>
        시행일: 2024년 02월 20일
      </Typography>
      <Typography paragraph>
      본 문서를 심도 있게 검토하여 읽어주십시오. <br/>
      본 문서에는 네오랩컨버전스(이하 “네오랩” 혹은 “당사”)가 당사의 소프트웨어, 어플리케이션, 업데이트, 패치 및 관련 서비스를 다운로드하고 설치하여 사용할 수 있는 권한을 부여하는 약관에 대한 내용이 포함되어 있습니다.
      </Typography>
      <Typography paragraph>
      네오랩 개인정보 보호정책(이하 “개인정보 보호정책”) 및 이용 약관(이하 “약관”) <br/>
      아래 첨부된 링크를 통해 내용을 확인할 수 있으며, 해당 내용은 최종 사용자 사용권 계약에 포함되는 내용입니다.
      </Typography>
      <Typography paragraph>
        [개인정보 처리 방침]
      </Typography>
      <Typography paragraph>
        [이용 약관]
        네오랩의 서비스를 다운로드 및 설치하거나 이용하는 행위를 통해, 귀하는
      <List>
        <ListItem>
          <ListItemText
            primary="(i) 본 최종 사용자 사용권 계약"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="(ii) 개인정보 보호정책"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="(iii) 이용 약관"
          />
        </ListItem>
      </List>
      에 포함되는 내용을 이해하였으며 이에 동의한다고 간주됩니다. 동의하지 않는 경우에는 서비스를 다운로드하거나 설치 및 이용을 하지 마시기 바랍니다.
      <List>
        <ListItem>
          <ListItemText
            secondary="*참고: 귀하가 만 18세 미만이거나 귀하의 국가 혹은 거주 지역에서 미성년자로 간주되는 경우, 귀하는 부모 혹은 법적 보호자(이하 “법적 보호자”)와 함께 본 문서를 검토하였으며, 귀하의 법적 보호자가 해당 문서의 약관을 동의 및 이해했음을 나타냅니다. "
          />
        </ListItem>
      </List>
      </Typography>
      <Typography paragraph>
      귀하의 법적 보호자가 미성년자일 경우, 귀하는
      <List>
        <ListItem>
          <ListItemText
            primary="(i) 본 문서의 약관에 대한 이해"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="(ii) 서비스 사용에 대한 모든 책임, 위험에 대한 감수"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="(iii) 정확하고 진실된 정보의 제공"
          />
        </ListItem>
      </List>
      을 보장하여야 합니다.
      </Typography>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        1. 이용권 및 제한사항
        </Typography>
        <Typography paragraph>
        네오랩은 귀하가 사용하는 기기에 당사의 서비스를 설치하거나 기기를 통해 당사의 서비스를 이용하는 것에 대한 비독점적이고 양도가 불가능하며 권리에 대한 재부여가 불가능하고 상업적 이용이 불가능한 제한적 이용권을 부여합니다. 당사의 서비스를 이용하기 위해 추가적 설치 및 업데이트, 패치 혹은 다른 변경사항이 요구될 수 있습니다.
        </Typography>
        <Typography paragraph>
        당사의 서비스에는 이러한 외부 공급 업체가 직접 관리하는 서비스 및 소프트웨어가 포함될 수 있으며, 이 중 일부는 본 문서와 별도로 해당 업체의 이용 약관의 동의해야 할 수 있으니, 동의하기 전 이에 해당하는 약관을 검토하시기 바랍니다.
        </Typography>
        <Typography paragraph>
        귀하는 본인 또는 타인에게 다음과 같은 사항을 허용하거나 양도할 수 없습니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="(i) 당사 소프트웨어의 상업적 이용"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(ii) 당사 소프트웨어의 수정, 패치, 복사, 번역"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(iii) 당사 소프트웨어 소스 코드의 전체 혹은 부분 도출"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(iv) 소유권 및 저작권을 나타내는 표식의 제거"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(v) 당사 서비스에 대해 승인되지 않거나 변형된 외부 소프트웨어의 이용"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(vi) 당사 서비스에 대해 승인되지 않은 접속, 유지, 제공을 하는 행위"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="(vii) 당사 서비스의 수출"
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          2. 소유권에 대하여
        </Typography>
        <Typography paragraph>
        당사는 당사의 서비스에 대한 모든 독점적 권리를 보유하고 있습니다. 당사의 서비스와 관련 저작물에서 등장하는 이미지, 그래픽, 디자인, 콘셉트, 도메인 이름, 업데이트, 패치 및 이에 대한 수정사항을 포함한 당사 서비스의 모든 영역이 이에 제한없이 포함됩니다. 관련 권리는 저작권 및 지적 재산권과 관련한 법률 등, 국제 조약 및 협약에 의해 보호되며, 본 계약에 명시적으로 부여된 사항 이외의 모든 권리는 당사가 보유하고 있습니다. 당사의 서비스를 부정한 방법으로 이용하거나 본 문서에 위배되는 행위를 하는 경우, 당사는 당사의 권리를 보호할 수 있도록 필요한 모든 조치를 취할 것입니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          3. 판매 또는 양도 금지
        </Typography>
        <Typography paragraph>
          당사는 당사의 서비스의 판매 및 양도를 금지하고 있습니다. 귀하는 당사의 동의 없이 본 문서에 기재된 귀하의 권리, 책임 및 의무의 전부 그리고 일부를 제공, 구매, 판매, 교환, 등록, 거래를 위한 계약을 어떠한 방식으로도 진행할 수 없으며, 이에 관한 모든 행위는 무효이며 효력이 없습니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          4. 정보 수집
        </Typography>
        <Typography paragraph>
          당사는 더 나은 서비스 서비스를 제공할 수 있도록 귀하의 접속 정보 및 운영 체제와 같은 정보를 수집 및 저장, 사용할 수 있습니다. 또한 귀하의 쿠키 데이터를 사용할 수 있습니다. 단, 쿠키 데이터는 귀하의 장치를 통해 기록을 중단할 수 있습니다.
        </Typography>
        <Typography paragraph>
          당사는 귀하의 개인 정보를 민감하게 다루고 있으며, 개인 정보 처리에 관한 내용은 개인정보 보호정책을 참고하여 주시기 바랍니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          5. 서비스 소프트웨어의 변경
        </Typography>
        <Typography paragraph>
          당사는 당사에서 제공하는 서비스에 대해 업데이트, 패치, 오류 수정, 버그 수정, 추가 콘텐츠를 포함하여 다양한 변경 사항을 가할 수 있습니다. 귀하가 서비스를 계속 이용할 때, 해당 사항이 반영된 파일을 설치 및 다운로드하여야 합니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        6. 본 계약의 종료
        </Typography>
        <Typography paragraph>
          본 계약은 종료될 때까지 유효합니다.<br/>
          본 계약은 다음과 같은 경우에 종료됩니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="(i) 귀하의 스마트기기에서 당사 서비스를 삭제하거나 제거할 때" />
          </ListItem>
          <ListItem>
            <ListItemText primary="(ii) 귀하의 계정을 삭제하고 당사 서비스의 이용을 중지할 때" />
          </ListItem>
        </List>
        <Typography paragraph>
        아울러 계정을 삭제할 경우, 서비스 ID, 서비스 내 진행상황, 서비스 이용에 대한 모든 정보가 완전히 삭제되며, 그 어떠한 방법으로도 복구하거나 접근할 수 없습니다.
        </Typography>
        <Typography paragraph>
        또한, 귀하가 본 문서의 약관을 위반하는 경우, 당사는 당사의 판단에 따라 사전 설명 및 통지와 함께, 혹은 사전 설명 및 통지 없이 본 계약을 종료할 수 있습니다.
        </Typography>
        <Typography paragraph>
        본 계약이 종료될 경우, 본 문서를 통해 부여된 모든 권리는 즉시 박탈됩니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        7. 품질 보증의 포기 및 책임의 제한
        </Typography>
        <Typography paragraph>
        귀하는 현행 법률에서 허용하고 있는 최대 범위 내에서 당사의 서비스를 사용함으로써 수반되는 모든 위험은 귀하가 감수해야 한다는 점에 동의합니다. 현행 법률에서 허용하는 최대한의 범위에서 당사 서비스의 모든 자료는 “있는 그대로”, “즉시 사용 가능한 형태로” 제공되며, 이에 대한 보증은 하지 않습니다. 당사의 서비스와 관련하여 타이틀, 상품 가치, 품질, 타인의 권리를 침해하지 않는 범위 내에서 특정 목적에 대한 적합성에 대해서는 보증이 제공되고 있습니다. 
        </Typography>
        <Typography paragraph>
          앞서 기술한 내용을 침해하지 않는 범위 내에서 
          <List>
            <ListItem>
              <ListItemText
              primary="(I) 귀하의 요구에 대한 만족" 
              />
            </ListItem>
            <ListItem>
              <ListItemText
              primary="(II) 보안, 오류, 이용에 대한 무결성" 
              />
            </ListItem>
            <ListItem>
              <ListItemText
              primary="(III) 당사 서비스의 정확성, 최신성, 신뢰도" 
              />
            </ListItem>
            <ListItem>
              <ListItemText
              primary="(IV) 당사의 서비스에서 발생한 결함 혹은 오류에 대한 수정 및 업데이트" 
              />
            </ListItem>
            <ListItem>
              <ListItemText
              primary="(V) 당사의 서비스에서 바이러스 및 유해한 요소의 발생" 
              />
            </ListItem>
          </List>
          에 대한 보증을 하지 않습니다. 또한 업데이트 진행 시, 이전 업데이트 버전에 대한 지원을 보장하지 않습니다.
        </Typography>
        <Typography paragraph>
          귀하는 귀하의 의지에 따라 당사의 서비스를 다운로드하고 사용하는 행위에 대한 모든 책임을 집니다. <br/>
          이에는
          <List>
            <ListItem>
              <ListItemText
              primary="(i) 귀하의 디바이스 시스템을 포함한 장치에 대한 손상" 
              />
            </ListItem>
            <ListItem>
              <ListItemText
              primary="(ii) 상기한 상황으로 인해 발생할 수 있는 데이터 손실 및 기타 손해" 
              />
            </ListItem>
          </List>
          에 대한 내용이 포함됩니다.
        </Typography>
        <Typography paragraph>
          현행 법에서 허용하는 최대한의 범위에서, 당사는 당사의 서비스 혹은 관련 서비스 사용과 관련하여 귀하의 사망을 포함한 어떠한 신체적 손해 혹은 손실에 대해 어떠한 책임도 지지 않습니다.
        </Typography>
        <Typography paragraph>
          또한 당사의 서비스를 이용함으로써 발생한 모든 종류의 손해에 대해 책임을 지지 않으며, 발생한 행위의 종류와 관계없이 당사의 책임은 귀하가 서비스에서 지불한 금액에만 국한됩니다. 귀하가 거주하는 지역에서 이와 같은 당사의 책임 범위를 인정하지 않는 경우에는, 현행 법에 따라 범위가 인정됩니다. 
        </Typography>
        <Typography paragraph>
          본 항목의 내용은 계약이 종료된 시점 이후에도 효력이 있습니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        8. 배상
        </Typography>
        <Typography paragraph>
          귀하는
          <List>
            <ListItem>
              <ListItemText
                primary="(I) 당사의 서비스 이용"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="(II) 본 계약에 대한 귀하의 위반"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="(III) 당사의 저작권, 상표, 영업비밀, 지적 재산권 및 제3자에 대한 권리 침해"
              />
            </ListItem>
          </List>
          로 인해 발생하는 모든 비용(변호사 비용 포함)에 대해, 당사가 지불할 책임이 없다는 사실에 대해 동의합니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          9. 계약 내용의 변경
        </Typography>
        <Typography paragraph>
          당사는 사전 통지 없이 본 계약의 특정 조항을 수정, 개정, 보완, 삭제를 할 수 있습니다. 이에, 귀하는 당사의 웹사이트에서 본 계약 내용의 최신 내용을 확인하고 검토할 책임이 있으며, 그에 대한 내용을 준수해야 합니다. 개정된 계약 내용을 준수할 수 없는 경우, 귀하는 6번 항목에 따라 본 계약을 종료할 수 있습니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          10. 조항의 독립성
        </Typography>
        <Typography paragraph>
          본 계약 자체, 또는 본 계약의 일부 조항이 무효화되는 경우에도, 무효화되지 않은 다른 조항에 영향을 미치지 않습니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          11. 기타
        </Typography>
        <Typography paragraph>
          당사의 서비스를 이용하기 위해서는 인터넷에 연결되어 있어야 하며, 해당 서비스를 작동시킬 수 있는 하드웨어 및 소프트웨어가 구비되어 있어야 합니다. 단, 당사는 인터넷 중단, 네트워크 인프라 장애, 외부 공격, 파업, 엠바고, 정부 지침에 따른 규제, 에너지 부족, 화재, 홍수를 포함한 자연 재해로 인한 서비스 장애에 대해 책임을 지지 않습니다.
        </Typography>
        <Typography paragraph>
          현행 법률에서 허용하는 범위 내에서, 본 계약은 유엔 협약을 위배하지 않으며 대한민국 법을 준수하며, 본 계약은 대한민국의 법에 따라 해석됩니다. 또한, 본 계약과 관련하여 발생하는 모든 분쟁과 관련하여 귀하는 대한민국 법원이 절대적 권한을 갖고 있음에 동의합니다. 또한 귀하는 대한민국 법원의 관할권 외의 지역에 소속되어 있다는 주장을 포기하는 데에 명시적으로 동의합니다.
        </Typography>
        <Typography paragraph>
          본 계약은 본 문서에서 다루는 내용과 관련하여 당사와 귀하 사이의 전적 합의가 되었음을 나타내며, 해당 주제에 대해 이해하였고 이해하고 있음을 나타냅니다. 또한 당사는 본 계약에 나타난 권리의 일부 혹은 전체를 언제든지 양도할 수 있음을 나타냅니다.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
          12. 연락처
        </Typography>
        <Typography paragraph>
        본 계약과 관련한 질문이 있을 경우, 당사의 고객 지원 팀으로 연락 주시기 바랍니다.
        </Typography>
        <Typography paragraph>
          <a href="mailto:contact@neolab.net">contact@neolab.net</a>
        </Typography>
      </Box>
    </Container>
  );
}

export default privacyKO;
