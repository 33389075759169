import React, { useState } from "react";

//포코로 블루투스 연결 요청
export function connectPokoro() {
  console.log("=== connectPokoro");
  window.Native?.connectPokoro();
}

//포코로 검색중지
export function stopScan() {
  console.log("=== stopScan");
  window.Native?.stopScan();
}

//포코로 블루투스 연결 종료 요청
export function disConnectPokoro() {
  console.log("=== disConnectPokoro");
  window.Native?.disConnectPokoro();
}

//포코로 wifi 검색 요청
export function startWifiScan() {
  console.log("=== startWifiScan");
  window.Native?.startWifiScan();
}

//포코로 wifi 연결 요청
export function wifiInputPassword(wifiName: string, pw: string) {
  console.log(`=== wifiInputPassword wifiName: ${wifiName} pw: ${pw}`);
  window.Native?.wifiInputPassword(wifiName, pw);
}

//포코로 Custom data 전달 요청(펌웨어 세팅관련)
export function customSendToken(jsonStr: string) {
  console.log(`=== customSendToken : ${jsonStr}`);
  window.Native?.customSendToken(jsonStr);
}

export function customSendSetting(jsonStr: string) {
  console.log(`=== customSendSetting : ${jsonStr}`);
  window.Native?.customSendSetting(jsonStr);
}

export function startlogin(url: string) {
  console.log(`=== startlogin : ${url}`);
  window.Native?.startlogin(url);
}

export function isConversation(isConversation: boolean) {
  console.log(`=== isConversation : ${isConversation}`);
  if (window.Native && typeof window.Native.isConversation === "function") {
    window.Native.isConversation(isConversation); // isConversation 함수가 있을 경우에만 호출
  } else {
    console.warn(
      "window.Native or window.Native.isConversation is not defined"
    );
  }
}

export function isPronunciation(isPronunciation: boolean) {
  console.log(`=== isPronunciation : ${isPronunciation}`);
  if (window.Native && typeof window.Native.isPronunciation === "function") {
    window.Native.isPronunciation(isPronunciation); // isPronunciation 함수가 있을 경우에만 호출
  } else {
    console.warn(
      "window.Native or window.Native.isPronunciation is not defined"
    );
  }
}

export function isReport(isReport: boolean) {
  console.log(`=== isReport : ${isReport}`);
  if (window.Native && typeof window.Native.isReport === "function") {
    window.Native.isReport(isReport); // isHome 함수가 있을 경우에만 호출
  } else {
    console.warn("window.Native or window.Native.isReport is not defined");
  }
}

export function isSetting(isSetting: boolean) {
  console.log(`=== isSetting : ${isSetting}`);
  if (window.Native && typeof window.Native.isSetting === "function") {
    window.Native.isSetting(isSetting); // isSetting 함수가 있을 경우에만 호출
  } else {
    console.warn("window.Native or window.Native.isSetting is not defined");
  }
}

export function isHome(isHome: boolean) {
  console.log(`=== isHome : ${isHome}`);
  if (window.Native && typeof window.Native.isHome === "function") {
    window.Native.isHome(isHome); // isHome 함수가 있을 경우에만 호출
  } else {
    console.warn("window.Native or window.Native.isHome is not defined");
  }
}


export function callBrowser(url: string) {
  console.log(`=== callBrowser : ${url}`);
  if (window.Native && typeof window.Native.callBrowser === "function") {
    window.Native.callBrowser(url); // callBrowser 함수가 있을 경우에만 호출
  } else {
    console.warn("window.Native or window.Native.callBrowser is not defined");
  }
}


//type : 0 jpg, 1 png, 2 pdf 
export function shareFileToAOS(type: number, fileName:string, encodeData:string) {
  console.log(`=== shareFileToAOS : ${type},${fileName},${encodeData}`);
  if (window.Native && typeof window.Native.callBrowser === "function") {
    window.Native.shareFileToAOS(type, fileName, encodeData); // shareFileToAOS 함수가 있을 경우에만 호출
  } else {
    console.warn("window.Native or window.Native.shareFileToAOS is not defined");
  }
}


// IOS 에서 리턴이 잘 동작하지 않아서 안쓰게될 구버전 인터페이스스
export function isConnectedPokoro() {
  console.log(`=== isConnectedPokoro `);
  if (window.Native && typeof window.Native.isConnectedPokoro === "function") {
    try {
      // 네이티브 함수 호출 및 결과 반환
      const result = window.Native.isConnectedPokoro();
      console.log("isConnectedPokoro result:", result);
      return result;
    } catch (error) {
      console.error("Error while calling window.Native.isConnectedPokoro:", error);
      return null; // 호출 실패 시 null 반환
    }
  } else {
    console.warn("window.Native or window.Native.isConnectedPokoro is not defined");
    return null; // 네이티브 함수가 없을 경우 null 반환
  }
}

// isConnectedPokoro 대신 사용하게될 신버전 인터페이스스
export function reqIsConnectedPokoro() {
  console.log(`=== reqIsConnectedPokoro `);
  if (window.Native && typeof window.Native.reqIsConnectedPokoro === "function") {
    window.Native.reqIsConnectedPokoro();

  } else {
    console.warn("window.Native or window.Native.reqIsConnectedPokoro is not defined");
    return null; // 네이티브 함수가 없을 경우 null 반환
  }
}
