import React from "react";
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';

function privacyEN(props) {
  return (
    <Container>
      <Typography variant="h4" mb={4} gutterBottom>
        Pokoro Privacy Policy
      </Typography>
      <Typography paragraph>
      Pokoro, a product of NeoLAB Convergence Inc. ("Company" or "Pokoro"), is committed to protecting your personal data and ensuring transparency in how we collect, use, and share it. This Privacy Policy outlines our practices and your rights under global privacy standards, including Children's Online Privacy Protection Act (COPPA) and the Personal Information Protection Act (PIPA) in South Korea.
      </Typography>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        1. Personal Information We Collect
        </Typography>
        <Typography paragraph>
        We collect personal information to enhance your experience with our services. We only collect data that is necessary for delivering a personalized and efficient service. The information we collect includes:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Account Information:"
              secondary="User ID, legal guardian’s name, legal guardian’s email address, and device IDs."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Child’s Information:"
              secondary=" Age, and birthday"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Child’s Biometric Information: "
              secondary="Voice data (only collected with legal guardian’s consent)"
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Service Usage Information: "
              secondary="Data on how your child interacts with Pokoro, including usage patterns and preferences."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Device Information: "
              secondary="Device ID, browser type, and operating system."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Audio Data:"
              secondary="Audio recordings captured through the device for speech recognition and AI response purposes."
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          2. Why We Collect This Information
        </Typography>
        <Typography paragraph>
        We collect and process personal data for the following purposes:
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="Service Provision: "
              secondary="To manage accounts, authenticate user identity, and offer personalized educational experience."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Service Improvement: "
              secondary="To enhance the functionality and educational value of Pokoro through usage analysis."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Personalized Learning: "
              secondary="To tailor educational content based on your child’s age and interaction history."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Legal Guardian’s Insights: "
              secondary="To provide legal guardians with insights into their child’s progress and learning experience."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Legal Compliance: "
              secondary="To comply with relevant legal obligations and protect against misuse of our services."
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        3. AI Ethics Policy
        </Typography>
        <Typography paragraph>
        Pokoro adheres to ethical principles in the development and deployment of artificial intelligence (AI). These principles ensure responsible and fair use of AI, with a commitment to human dignity, non-discrimination, privacy protection, and environmental sustainability. We do not use AI for purposes beyond what is disclosed, and all AI interactions are designed with transparency and user control in mind.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        4. Legal Basis for Processing
        </Typography>
        <Typography paragraph>
        Depending on your location, the legal basis for processing personal data may include:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Consent: "
              secondary="Explicit consent provided by the parent/legal guardian."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Contractual Necessity: "
              secondary="Processing required to fulfill the contract for service provision"
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Legal Obligations: "
              secondary="Compliance with legal or regulatory requirements"
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="egitimate Interests: "
              secondary="Processing necessary for the Company's legitimate business interests, balanced against your rights and interests."
              />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        5. Sharing of Personal Information
        </Typography>
        <Typography paragraph>
          i) We do not share your personal information with third parties except in the following circumstances:
          <List>
            <ListItem>
              <ListItemText 
                primary="With Your Consent: "
                secondary="We will share your information with third parties only with your explicit consent."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Service Providers: "
                secondary="We share data with third-party service providers who assist with data processing, customer support, and analytics. These providers are contractually obligated to protect your data and comply with COPPA and other privacy regulations."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Legal Requirements: "
                secondary="We may disclose your information as required by law, such as to comply with a subpoena, or to protect the rights, property, or safety of our company, our users, or others."
                />
            </ListItem>
          </List>
        </Typography>
        <Typography paragraph>
          i) We may share your Personal Information with:
          <p>Third-party vendors and other service providers that perform services on our behalf, in order to carry out their work for us, which may include, for example, billing, payments, service fulfillment, web hosting or providing analytic services. We evaluate third-party vendors to ensure they meet our privacy standards. More specifically, we may share the following types of information with the third parties listed below:
          </p>
          <List>
            <ListItem>
              <ListItemText 
                primary="With YoGoogle (Text-to-Speech AI):"
                secondary="We send children's voice recordings to Google's TTS server for conversion to text to allow the device to function."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="OpenAI (GPT API):"
                secondary="Converted text is shared with OpenAI’s GPT API to generate conversational prompts for learning."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Naver CLOVA (Text-to-Speech AI):"
                secondary="Depending on the language, we use Naver CLOVA or Google TTS to convert educational prompts into audio responses. For example, CLOVA is used for Korean, and Google TTS is used for English."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="All shared information is strictly for the functioning of the device, such as turning speech into text, analyzing content, and generating educational prompts. No data is shared for marketing or advertising purposes, and these providers are bound to comply with COPPA."
                />
            </ListItem>
          </List>
        </Typography>
        <Typography paragraph>
          We may also share Personal Information with our parent company, subsidiaries and affiliates, and investors primarily for business and operational purposes so long as any recipient agrees to comply with this Privacy Policy and applicable law with regard to such Personal Information. In the event that we go through a business transition, such as a merger, acquisition by another company, or sale of all or a portion of our assets, bankruptcy, or other corporate change, including, without limitation, during the course of any due diligence process, your information, including Personal Information, will likely be among the assets transferred.
        </Typography>
        <Typography paragraph>
          You will be notified via email and/or a prominent notice on Services of any completed change in ownership or uses of your Personal Information, as well as any choices you may have regarding your Personal Information. This Privacy Policy will become binding upon the new owner of the information until amended.
        </Typography>
        <Typography paragraph>
          Despite the above, we confirm that no personal information has been shared with third parties in the preceding 12 months nor have they been disclosed for any business purposes. iii) How do we work with third parties?
        </Typography>
        <Typography paragraph>
          iii) Third-Party Advertising
          <p>We do not share children’s personal information for advertising or marketing purposes, ensuring compliance with COPPA. However, in cases where we share data with third-party service providers for operational reasons (e.g., data hosting, analytics), we ensure that these entities also comply with COPPA and are contractually obligated to protect your child’s privacy.
We will inform legal guardians if children's data needs to be shared with third parties for legitimate operational purposes, and we guarantee that these parties are prohibited from using the data for any unauthorized purposes. Legal guardians retain full control over their child’s data and can request more information about third-party sharing at any time.
          </p>
        </Typography>
        <Typography paragraph>
          iii) Third-Party Advertising
          <p>If We offer third-party sign-in services (such as Facebook or Apple), we ensure that these services comply with COPPA when handling children's personal information. We take steps to restrict access for children under 13 to features that could compromise their privacy, unless verifiable parental consent from the legal guardian has been obtained.</p>
          <p>No child’s personal information will be shared with these third parties without explicit  consent of the legal guardian. Additionally, legal guardians will be informed about any third-party services their child may interact with through the our platform.
          </p>
        </Typography>
        <Typography paragraph>
          v) Third-Party Service Providers
          <p>We work with service providers like Oracle Cloud Infrastructure and similar organizations to provide us with information regarding traffic on the Services, including the features used when visiting the Services and to provide us with information regarding the use of the Services.
          </p>
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          6. International Data Transfers
        </Typography>
        <Typography paragraph>
          Your personal information may be transferred to and processed in countries outside of your jurisdiction, including countries that may not have the same data protection laws as your own. When we transfer your data internationally, we ensure that such transfers comply with applicable data protection laws by implementing appropriate safeguards, such as Standard Contractual Clauses (SCCs) approved by the EU Commission, or other legally recognized mechanisms.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          7. Retention of Personal Information
        </Typography>
        <Typography paragraph>
          We retain personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law to comply with our legal, accounting, or reporting requirements. When the personal information is no longer necessary, we will securely destroy it in accordance with applicable regulations and our internal policies. 
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          8. User Content and Security Measures
        </Typography>
        <Typography paragraph>
          We take the privacy and security of your personal data very seriously. All personal information, including user-generated content such as voice recordings, is protected through robust security protocols. Only authorized personnel have access to your data, and access is strictly monitored to prevent unauthorized use or misuse.
        </Typography>
        <Typography paragraph>
        Our security measures include:
          <List>
            <ListItem>
              <ListItemText 
                primary="Access Controls:"
                secondary="Access to personal data is restricted to authorized personnel who are bound by strict confidentiality obligations."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Regular Audits:"
                secondary="We conduct regular audits of our security practices to ensure compliance with industry standards and legal requirements."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Data Breach Notification:"
                secondary="In the event of a data breach, we will notify affected users and relevant authorities promptly, in compliance with applicable laws. We take immediate steps to contain the breach, restore system integrity, and prevent future incidents."
                />
            </ListItem>
          </List>
        </Typography>
        <Typography paragraph>
          Parents or legal guardians maintain full control over their child’s personal information. You can access, review, edit, or delete your child's data at any time through the Legal Guardian App or by contacting our support team. Upon request, we will provide you with a copy of your data or delete it, subject to legal retention requirements.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        9. Use of Cookies and Tracking Technologies
        </Typography>
        <Typography paragraph>
        We use cookies and similar technologies to enhance the user experience:
        <List>
          <ListItem>
            <ListItemText
              primary="Essential Cookies:"
              secondary="Necessary for core functionalities like session management."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Performance Cookies:"
              secondary="Used to analyze service performance and improve the user experience."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Personalization Cookies:"
              secondary="Enable personalized content and recommendations based on user preferences."
            />
          </ListItem>
        </List>
        </Typography>
        <Typography paragraph>
          Legal guardians can manage cookie preferences through their browser settings. In regions under GDPR and similar regulations, users may be prompted with a consent banner to manage their cookie preferences in compliance with local laws.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          10. Children’s Privacy
        </Typography>
        <Typography paragraph>
          Pokoro is designed with children's privacy in mind, and we comply with relevant regulations such as COPPA (U.S.), GDPR (EU), and PIPA (South Korea). We require verifiable parental consent from the legal guardians before a child under 13 can use our services. Parents or guardians can manage their child's account, review, correct, or delete personal information, and opt-out of further data collection. 
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          11. Control and Insights of Legal Guardians
        </Typography>
        <Typography paragraph>
          We provide legal guardians with detailed insights into their child’s learning progress, allowing them to tailor the educational experience to meet their child’s needs. These insights are based on the child’s interactions with the device, child’s age and are designed to support personalized education while maintaining the child’s privacy.<br/>
        Through the Legal Guardian App, legal guardians can easily review and manage their child’s personal information, including age, voice data, and other usage details collected through the platform. Additionally, legal guardians may request access to, review, or delete their child’s data by contacting our customer support team at [insert contact information]. Our team will assist you in processing the request efficiently.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          12. What is Verified Parental Consent?
        </Typography>
        <Typography paragraph>
          In our unwavering commitment to safeguarding the privacy and well-being of children, the implementation of Verified Parental Consent (VPC) by the child’s legal guardian is a crucial and mandatory step within our platform.
        </Typography>
        <Typography paragraph>
          Anchored in compliance with the Children's Online Privacy Protection Act (COPPA), a United States federal law designed to fortify the digital privacy of children under 13, VPC serves as a robust shield against any potential misuse of sensitive information. COPPA mandates that operators of websites or online services directed toward children must secure VPC before allowing young users access to their platform.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          13. Why is Verified Consent from Legal Guardians Necessary?
        </Typography>
        <Typography paragraph>
          By completing Verified Parental Consent, you are giving explicit permission for your child to access and use our product. This process ensures that any personal information collected is handled responsibly and securely, with full transparency. Your child’s privacy and safety are our top priorities, and we adhere to stringent guidelines to safeguard their data.
        </Typography>
        <Typography paragraph>
          This process is not only a regulatory requirement under the Federal Trade Commission (FTC) guidelines but also an important step to ensure that you, as the legal guardian, have full control over your child’s data. You have the right to review, update, or delete your child's information at any time by contacting us.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          14. Why and what information about my kid are you collecting?
        </Typography>
        <Typography paragraph>
          We collect your child's age and voice to offer age-appropriate content and personalized experiences while using Pokoro. This information is essential for improving the product's functionality and enhancing your child’s experience. Voice data is necessary for speech recognition and providing conversational contents to the user. We only collect the minimal amount of information necessary for these purposes and assurance that no additional personal information is collected without further consent.
        </Typography>
        <Typography paragraph>
          In compliance with the Children's Online Privacy Protection Act (COPPA), we require verifiable parental consent before collecting any personal information from children under the age of 13. We do not collect more information than is reasonably necessary, and we take appropriate measures to ensure the security and privacy of the data collected.
        </Typography>
        <Typography paragraph>
          If you do not wish for us to collect this information, you may choose not to provide consent. You also have the right to revoke your consent at any time and request the deletion of any data collected by contacting us.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          15. How do I complete the Verified Parental Consent Process?
        </Typography>
        <Typography paragraph>
          Verified Parental Consent Process (COPPA-Compliant)
        </Typography>
        <Typography paragraph>
          In compliance with the Children’s Online Privacy Protection Act (COPPA), We require verifiable parental consent from the child’s legal guardian before collecting any personal information from children under 13. Below are the two methods you can use to provide your consent, each designed to verify that you are a parent or legal guardian.
        </Typography>
        <Typography paragraph>
          1. Consent via Small Credit/Debit Card Transaction
          <List>
            <ListItem>
              <ListItemText 
                primary="Step-by-Step Instructions:"
                secondary={
                  <>
                  <p>Press 'Confirm and Verify Identity' on the Legal Guardian Consent page that automatically appears in the Legal Guardian app while pairing with Pokoro.</p>
                  <p>You will be redirected to a secure payment page where you will be asked to verify your identity by completing a small transaction of $0.50 using a credit or debit card.</p>
                  </>
                  } 
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Why This is Required:"
                secondary="COPPA requires us to verify that the person providing consent is an adult. By using a credit or debit card, we can confirm that you are legally eligible to provide consent since these cards are typically only issued to adults."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Important Notes:"
                secondary={
                  <>
                    <p>The $0.50 charge is a one-time, non-refundable fee and serves as proof of consent and identity.</p>
                    <p>This method is safe and secure, with your payment information encrypted and used solely for the purpose of identity verification.</p>
                    <p>Once the transaction is completed, your consent will be verified and your child can begin using the Pokoro device.</p>
                  </>
                  } 
                />
            </ListItem>
          </List>
        </Typography>
        <Typography paragraph>
          2. Consent via Signed Consent Form
          <List>
            <ListItem>
              <ListItemText 
                primary="Step-by-Step Instructions:"
                secondary={
                  <>
                  <p>You can also choose to give consent by completing a signed consent form.</p>
                  <p>After initiating the consent process through the Legal Guardian App, you will receive an email containing the consent form.</p>
                  <p>Download the form, fill it out with your child’s information and your signature.</p>
                  <p>Once completed, you will need to submit the signed form by either:</p>
                  <p>Uploading it through a secure link provided in the email, or Replying to the email with the signed form attached.</p>
                  </>
                } 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Why This is Required:"
                secondary="COPPA mandates that we obtain verifiable consent from parents or legal guardians before collecting personal information from children. By requiring a signed form, we can confirm that the person giving consent is the parent or legal guardian of the child using the Pokoro device."
                />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Important Notes:"
                secondary={
                  <>
                    <p>Ensure that all required fields on the consent form are properly filled out, including your child's name and your signature.</p>
                    <p>Once we receive and verify the signed form, you will receive a confirmation, and your child can begin using the Pokoro device.</p>
                    <p>If you encounter any issues submitting the form, feel free to contact our customer support team at <a href="mailto:support_pokoro@neolab.net">[support_pokoro@neolab.net]</a>
                    </p>
                  </>
                  } 
                />
            </ListItem>
          </List>
        </Typography>
        <Typography paragraph>
          Your Rights and Control Over Your Child's Data
        </Typography>
        <Typography paragraph>
        After you have provided consent using one of the methods above, you will have full control over your personal information and that of your child’s personal information, included those listed below, through the Legal Guardian App or by contacting us directly:
        <List>
            <ListItem>
              <ListItemText 
                primary="Request information regarding the categories of personal information we have collected about you and your child, the categories of sources from which the personal information was collected, our business or commercial purpose for collecting, sharing, or selling personal information, the categories of third parties to whom we disclose personal information (if any), and the specific pieces of personal information we have collected about you."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Opt-out of the sale or sharing of your child’s personal information or your personal information."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Limit the use and disclosure of your child’s personal information and your personal information."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Request correction of any inaccurate personal information."
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Withdraw your consent at any time by contacting us at [insert contact information], at which point we will stop collecting data and delete any previously collected information (unless we are required to retain it by law)."
              />
            </ListItem>
          </List>
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          16. Is my child's data safe?
        </Typography>
        <Typography paragraph>
          The only data we collect from your child includes basic details such as their age and voice, which are necessary to provide personalized experiences within the Pokoro product.
        </Typography>
        <Typography paragraph>
          As a legal guardian, you have full control over your child's data. Legal guardians can manage their child’s data by:
        </Typography>
        <Typography paragraph>
          Accessing the Legal Guardian Portal: Through the Legal Guardian App, legal guardians can easily review and manage their child’s personal information, including their age, voice data, and other usage details collected through the platform.
        </Typography>
        <Typography paragraph>
          Contacting Customer Support: Legal Guardians can request to access, review, or delete their child’s data by contacting our customer support team via email at [support_pokoro@neolab.net]. Our team will assist you in processing the request efficiently.
        </Typography>
        <Typography paragraph>
          We assure that upon receiving a deletion request, any personal information about your child will be permanently removed from our systems within a reasonable timeframe, typically within 30 days, unless otherwise required by law.
        </Typography>
        <Typography paragraph>
          Your child’s data privacy and security are our top priorities, and we adhere to strict protocols to ensure compliance with COPPA and other applicable data protection regulations.
        </Typography>
        <Typography paragraph>
          Pokoro is fully compliant with the Children's Online Privacy Protection Act (COPPA). This means we adhere to the Federal Trade Commission (FTC) regulations to safeguard your child's privacy and ensure their safety while using our services.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          17. Updates to This Privacy Policy
        </Typography>
        <Typography paragraph>
          In compliance with COPPA, any significant changes to this privacy policy that may affect how children’s personal information is collected, used, or shared will be clearly communicated to the legal guardians. We will notify you through the Legal Guardian App and/or via email prior to any changes taking effect.
        </Typography>
        <Typography paragraph>
          These updates will be prominent and easily understandable, especially regarding any modifications that impact children’s data. We encourage legal guardians to review these notifications to stay informed about any updates to our data privacy practices.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          18. Contact Information
        </Typography>
        <Typography paragraph>
          For questions or concerns regarding this Privacy Policy or our privacy practices, please contact our Privacy Officer:
          <List>
            <ListItem>
              <ListItemText 
                primary="Name:"
                secondary="Suyul Kook" 
              />
            </ListItem>
            <ListItem>
              <ListItemText 
                primary="Email:"
                secondary={<a href="mailto:contact@neolab.net">contact@neolab.net</a>} 
              />
            </ListItem>
          </List>
          By using Pokoro, you acknowledge and agree to the terms outlined in this Privacy Policy.
        </Typography>
      </Box>

      <Typography variant="h5" mb={4} gutterBottom mt={13}>
        Annex: GDPR Privacy Policy (For EU Users)
      </Typography>
      <Typography paragraph>
        Effective Date: 1st November 2024<br/>
        Last Updated: 1st November 2024
      </Typography>
      <Typography paragraph>
      Pokoro, a product of NeoLAB Convergence Inc. ("Company" or "Pokoro"), complies with the General Data Protection Regulation (GDPR) applicable in the European Union (EU). For users in the UK, we comply with the UK General Data Protection Regulation (UK GDPR). However, this policy specifically addresses the rights and protections of EU users under the EU GDPR.
      </Typography>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        1. Personal Data We Collect (EU GDPR)
        </Typography>
        <Typography paragraph>
        We collect and process the following types of personal data in accordance with the EU GDPR:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Basic Account Information: "
              secondary="Includes the User ID, and the name and email address of the parent or legal guardian."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Child's Information: "
              secondary="Includes the child’s age, birthday, and voice data (collected only with explicit parental consent)."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Service Usage Data: "
              secondary="Information about interactions with the Pokoro product, including usage patterns and device-related data (e.g., device ID, browser type)."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Audio Data: "
              secondary="Voice recordings used for speech recognition and AI interaction (collected with consent)."
              />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        2. Legal Basis for Processing
        </Typography>
        <Typography paragraph>
        Under the EU GDPR, we process personal data based on the following lawful grounds:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Consent: "
              secondary="We obtain explicit parental or legal guardian consent to process children's data, particularly sensitive data like voice recordings (GDPR Article 8)."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Contractual Necessity: "
              secondary="Processing is required to provide Pokoro’s services as per the contract between you and Pokoro (GDPR Article 6(1)(b))."/>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Legal Obligation: "
              secondary="We process data to comply with legal obligations under EU law (GDPR Article 6(1)(c))."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Legitimate Interests: "
              secondary="We process data to improve our services, provided that these interests do not override your fundamental rights and freedoms (GDPR Article 6(1)(f))."
              />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          3. Your Data Protection Rights
        </Typography>
        <Typography paragraph>
          As an EU user, you have the following rights under the EU GDPR:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Right to Access: "
              secondary="You may request access to your personal data or your child’s data (GDPR Article 15)."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Right to Rectification: "
              secondary="You have the right to request the correction of any inaccurate or incomplete personal data (GDPR Article 16)."/>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Right to Erasure: "
              secondary="Under certain conditions, such as when you withdraw consent or if the data is no longer necessary for the original purposes, you can request the deletion of personal data (GDPR Article 17)."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Right to Restriction of Processing: "
              secondary="You can request the restriction of processing under specific circumstances (GDPR Article 18)."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Right to Data Portability: "
              secondary="You have the right to request the transfer of your personal data to another service provider in a structured, machine-readable format (GDPR Article 20)."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Right to Object: "
              secondary="You can object to the processing of your personal data when we rely on legitimate interests as the legal basis (GDPR Article 21)."
              />
          </ListItem>
        </List>
        <Typography paragraph>
          To exercise these rights, please contact us as outlined in Section 7.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        4. Data Retention
        </Typography>
        <Typography paragraph>
        We retain personal data only for as long as necessary to fulfill the purposes outlined in this policy or as required by EU law pursuant to Section 7 of this Privacy Policy, after which the personal data will be securely deleted or anonymized.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        5. International Data Transfers
        </Typography>
        <Typography paragraph>
        We may transfer your personal data to countries outside the EU. In such cases, we ensure that appropriate safeguards are in place, including:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Standard Contractual Clauses (SCCs)"
              secondary="approved by the European Commission to ensure adequate protection of your data during international transfers (GDPR Article 46)."
              />
          </ListItem>
        </List>
        <Typography paragraph>
        Where necessary, we also adopt additional protective measures, such as encryption and data minimization, to further secure your data during international transfers.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        6. Data Security
        </Typography>
        <Typography paragraph>
        We take appropriate technical and organizational measures to safeguard personal data from unauthorized access, alteration, disclosure, or destruction. These include:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Access Controls:"
              secondary="We restrict access to personal data to authorized personnel only."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Regular Audits:"
              secondary="We conduct regular security assessments to ensure continued compliance with GDPR."
              />
          </ListItem>
        </List>
        <Typography paragraph>
        Where necessary, we also adopt additional protective measures, such as encryption and data minimization, to further secure your data during international transfers.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        7. EU Representative
        </Typography>
        <Typography paragraph>
        We have appointed an EU representative to oversee compliance with the EU GDPR. Since we are not yet established in the EU, this is not a Data Protection Officer (DPO) as defined under GDPR. If the company’s activities expand to require a DPO, we will appoint one in compliance with the regulations.
        </Typography>
        <Typography paragraph>
        If you have any questions or concerns regarding your personal data, or wish to exercise any of your data protection rights, you may contact our EU representative:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Name:"
              secondary="Rickert Rechtsanwaltsgesellschaft m.b.H. (hereinafter: Law Firm)"
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="Email"
              secondary={
                <>
                  <a href="mailto:info@rickert.law ">info@rickert.law </a>
                  (data protection officer email: <a href="mailto:datenschutz@rickert.net ">datenschutz@rickert.net</a>)
                </>
              }
              />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        8. Complaints
        </Typography>
        <Typography paragraph>
        If you believe your personal data has been handled in a manner inconsistent with the EU GDPR, you have the right to lodge a complaint with your local data protection authority. For example:
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="Germany:"
              secondary={
                <>
                You can contact the Federal Commissioner for Data Protection and Freedom of Information at <a href="https://www.bfdi.bund.de">https://www.bfdi.bund.de</a>
                </>
                }
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="France:"
              secondary={
                <>
                  You can contact the Commission Nationale de l'Informatique et des Libertés (CNIL) at <a href="https://www.cnil.fr">https://www.cnil.fr </a>
                </>
              }
              />
          </ListItem>
        </List>
      </Box>

    </Container>
  );
}

export default privacyEN;
