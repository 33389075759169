import {makeAutoObservable} from "mobx";


export const TermsOfUseUrl = 'https://spectrum-tank-5ab.notion.site/812584ac564a4960b141d0678edfcf7c?pvs=4';
export const TermsOfPrivacyUrl = 'https://spectrum-tank-5ab.notion.site/de444839176d421aaf8d03d841fd382a?pvs=4';

const logPrefix  = "[UserStore]"
export default class UserStore {
    constructor(props) {
        this.userRepository = props.userRepository;

        this.init();

        makeAutoObservable(this);
    }

    init = () => {
        this.isUserDeviceListLoading = false;
        this.isUserWeekListLoading = false;
        this.isUserDashboardLoading = false;

        this.selectedDevice = '';
        this.newSelectedDevice = '';
        this.userDeviceList = [];

        this.selectedWeekOfMonth = '';
        this.userWeekOfMonthList = [];

        this.userDashboardData = '';

        this.newPokoroDialogOpen = false;
    }

    get getIsLoading() {
        return this.isUserDeviceListLoading || this.isUserWeekListLoading || this.isUserDashboardLoading;
    };

    setSelectedDevice = (device) => {
        this.selectedDevice = device;
    }

    setNewSelectedDevice = (device) => {
        this.newSelectedDevice = device;
    }

    setSelectedWeekOfMonth = (weekOfMonth) => {
        this.selectedWeekOfMonth = weekOfMonth;
    }

    * getUserDeviceList(userId, callback) {
        try {
            console.log(logPrefix, 'Start getUserDeviceList.');
            this.isUserDeviceListLoading = true;

            const response = yield this.userRepository.getUserDeviceList(userId);
            if (response.data.length > 0) {
                this.userDeviceList = response.data;
                this.selectedDevice = this.userDeviceList[0];
            }

            this.isUserDeviceListLoading = false;
            console.log(logPrefix, 'Finished getUserDeviceList.');

            if (this.selectedDevice !== '') {
                callback && callback(userId, this.selectedDevice.deviceId);
            }
        } catch(e) {
            this.selectedDevice = '';
            this.userDeviceList = [];
            console.log(logPrefix, 'Failed getUserDeviceList. error: ' + e);
        } finally {
            this.isUserDeviceListLoading = false;
        }
    }

    * getUserWeekOfMonthList(userId, callback) {
        try {
            console.log(logPrefix, 'Start getUserWeekOfMonthList.');
            this.isUserWeekListLoading = true;

            const response = yield this.userRepository.getUserWeekList(userId, this.selectedDevice.deviceId);
            if (response.data.length > 0) {
                this.userWeekOfMonthList = response.data;
                this.selectedWeekOfMonth = this.userWeekOfMonthList[0];
            }

            this.isUserWeekListLoading = false;
            console.log(logPrefix, 'Finished getUserWeekOfMonthList.');

            if (this.selectedWeekOfMonth !== '') {
                callback && callback(userId);
            }
        } catch(e) {
            this.selectedWeekOfMonth = '';
            this.userWeekOfMonthList = [];
            console.log(logPrefix, 'Failed getUserWeekOfMonthList. error: ' + e);
        } finally {
            this.isUserWeekListLoading = false;
        }
    }

    * getUserDashboard(userId, callback) {
        try {
            console.log(logPrefix, 'Start getUserDashboard.');
            this.isUserDashboardLoading = true;

            let url = `${userId}/dashboard?deviceId=${this.selectedDevice.deviceId}&year=${this.selectedWeekOfMonth.year}&month=${this.selectedWeekOfMonth.month}&week=${this.selectedWeekOfMonth.week}`;
            const response = yield this.userRepository.getUserDashboard(url);

            this.userDashboardData = response.data;

            this.isUserDashboardLoading = false;
            console.log(logPrefix, 'Finished getUserDashboard.');

            callback && callback();
        } catch(e) {
            this.userDashboardData = '';
            console.log(logPrefix, 'Failed getUserDashboard. error: ' + e);
        } finally {
            this.isUserDashboardLoading = false;
        }
    }

    * updatePenName(history, userId, penName, callback) {
        if (this.selectedDevice !== '') {
            try {
                console.log(logPrefix, 'Start updatePenName.');

                const param = {
                    deviceName: penName
                }

                yield this.userRepository.updatePenName(userId, this.selectedDevice.deviceId, param);
                this.selectedDevice.deviceName = penName;

                console.log(logPrefix, 'Finished updatePenName.');

                callback && callback();
            } catch(e) {
                console.log(logPrefix, 'Failed updatePenName. error: ' + e);
            }
        }
        history.push('/setting');
    }

    * deleteUser(userId, callback) {
        try {
            console.log(logPrefix, 'Start deleteUser.');

            yield this.userRepository.deleteUser(userId);

            console.log(logPrefix, 'Finished deleteUser.');

            callback && callback();
        } catch(e) {
            console.log(logPrefix, 'Failed deleteUser. error: ' + e);
        }
    }
}
