import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Box } from "@mui/material";
import CommonBack from "../CommonComponent/CommonBack";
import CommonButton from "../CommonComponent/CommonButton";
import {
  SignInTitleTextStyle,
  SignInTextStyle,
  TextBox,
  InfoBox,
  SignInContentsBox,
  SignInRoot,
  ProfileImage,
  InputField,
  NoteText,
} from "./Styled/SignInStyled";
import PokoroPen from "../../common/Images/PokoroPen.png";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";
import CommonDialog from "../CommonComponent/CommonDialog";

function SignInInfo(props) {
  const { intl, authStore } = props;
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [nickname, setNickname] = useState(authStore.nickname);
  const [nicknameError, setNicknameError] = useState('');
  const [penName, setPenName] = useState(authStore.penName);
  const [penNameError, setPenNameError] = useState('');

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
  };


  const handleChangeNickname = (e) => {
    const value = e.target.value;
    if (value.length <= 16) {
    setNickname(value);
    authStore.setNickname(value);
    }
  };
  

  const handleChangePenName = (e) => {
    const value = e.target.value;
    if (value.length <= 16) {
    setPenName(value);
    authStore.setPenName(value);
  }
  };

  const handleChangeBirthDate = (e) => {
    authStore.setBirthDate(e.target.value);
  };

  const handleClick = () => {
    authStore.updateUserInfo(() => authStore.checkLogin());
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = () => {
    setOpen(false);
  };

  const moveLoginPage = () => {
    authStore.doLogout(() => history.push("/"));
  };

  const isBirthDateValid = (date) => {
    if (date === '') {
      return true;
    }

    if (typeof date === 'number') {
      date = date.toString();
    }
    // 정규 표현식으로 기본 형식 및 년도 범위 검사 (1900-1999, 2000-2099)
    if (!/^(19\d{2}|20\d{2})(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/.test(date)) {
      return false;
    }

    // 연도, 월, 일을 분리
    const year = parseInt(date.substring(0, 4), 10);
    const month = parseInt(date.substring(4, 6), 10) - 1; // 월은 0부터 시작
    const day = parseInt(date.substring(6, 8), 10);

    // Date 객체를 사용하여 유효한 날짜인지 확인
    const birthDate = new Date(year, month, day);
    return (
      birthDate.getFullYear() === year &&
      birthDate.getMonth() === month &&
      birthDate.getDate() === day
    );
  };

  const isButtonDisabled = () => {
    return (
      authStore.isNotAcceptableNickname(intl, authStore.nickname) !== '' ||
      authStore.nickname.trim() === '' ||
      authStore.isNotAcceptablePenName(intl, authStore.penName) !== '' ||
      !isBirthDateValid(authStore.birthDate)
    );
  };

  return (
    <SignInRoot>
      <CommonBack onClick={handleClickOpen} />

      <SignInContentsBox>
        <InfoBox>
          <TextBox>
            <SignInTitleTextStyle>{intl.formatMessage({ id: "signIn_Title" })}</SignInTitleTextStyle> {/* 포코로 정보 입력 */}
            <SignInTextStyle><pre>{intl.formatMessage({ id: "signIn_Text" })}</pre></SignInTextStyle>
          </TextBox>
{/*           <Box sx={{ position: "relative" }}>
            <ProfileImage>
              <img src={PokoroPen} alt="pokoroImg" width="70%" />
            </ProfileImage>
          </Box> */}
          <Box style={{width: "100%"}}>
            <InputField
              label={intl.formatMessage({ id: "nickname_label" })} /* 계정 닉네임 */
              required
              sx={{ marginBottom: "4px" }}
              /* value={authStore.nickname} */
              value={nickname}
              onChange={handleChangeNickname}
              autoComplete="off"
              error={nicknameError !== ''}
              helperText={nicknameError}
              InputLabelProps={{
                sx: {
                  "& .MuiInputLabel-asterisk": { color: "#FF0000" },
                },
              }}
            />
            {authStore.isNotAcceptableNickname(intl, authStore.nickname) !== '' ?
                <NoteText style={{color: "#FE536B"}}>* {authStore.isNotAcceptableNickname(intl, authStore.nickname)}</NoteText>
                :
                <NoteText></NoteText>
            }
            <InputField
              label={intl.formatMessage({ id: "penName_label" })} /* 펜 이름 */
              sx={{ marginBottom: "4px" }}
              /* value={authStore.penName} */
              value={penName}
              onChange={handleChangePenName}
              autoComplete="off"
              error={penNameError !== ''}
              helperText={penNameError}
              InputLabelProps={{
                sx: {
                  "& .MuiInputLabel-asterisk": { color: "#FF0000" },
                },
              }}
            />
            {authStore.isNotAcceptablePenName(intl, authStore.penName) !== '' ?
                <NoteText style={{color: "#FE536B"}}>* {authStore.isNotAcceptablePenName(intl, authStore.penName)}</NoteText>
                :
                <NoteText>{intl.formatMessage({ id: "pen_note" })}</NoteText>
            }
            <InputField
              type="tel"  
              onInput={handleInput}
              label={intl.formatMessage({ id: "birthDate_label" })} /* 사용자 생년월일 */
              // required
              placeholder={intl.formatMessage({ id: "birthDate_placeholder" })} /* 예: 20191205 */
              sx={{ marginBottom: "4px" }}
              value={authStore.birthDate}
              onChange={handleChangeBirthDate}
              autoComplete="off"
              InputLabelProps={{
                sx: {
                  "& .MuiInputLabel-asterisk": { color: "#FF0000" },
                },
              }}
            />
            <NoteText style={{whiteSpace: 'pre-wrap'}}>{intl.formatMessage({ id: "birthDate_note" })}</NoteText> {/* 실제 포코로 이용자의 생년월일을 기입해주세요. */}
          </Box>
        </InfoBox>
        <CommonButton
          text={intl.formatMessage({ id: "register_ButtonText" })}
          background={"#FDD751"}
          color={"#333"}
          disabled={isButtonDisabled()}
          onClick={handleClick}
        />
      </SignInContentsBox>

      <CommonDialog
        open={open}
        title={intl.formatMessage({ id: "signInfo_DialogTitle" })}
        subText={<pre>{intl.formatMessage({ id: "signIn_DialogSubText" })}</pre>}
        onClick1={moveLoginPage}
        onClick2={handleClickClose}
        LeftButtonText={intl.formatMessage({ id: "dialog_YesButton" })}
        RightButtonText={intl.formatMessage({ id: "dialog_NoButton" })}
      />
    </SignInRoot>
  );
}

export default injectIntl(inject("authStore")(observer(SignInInfo)));
