import React, {useEffect, useRef} from "react";
import { injectIntl } from "react-intl";
import { Avatar, Box } from "@mui/material";
import AvatarPokoro from "../../../common/Images/AvatarPokoro.svg";
import {
  BadgeStyle,
  CardBoxStyle,
  DateTextStyle,
  ListContent,
  ListTitle,
  Root,
  TitleBox,
} from "./Styled/ConversationContentComponentStyled";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import {DATE_UTIL} from "../../../common/util/date.util";

function ConversationContentComponent(props) {
  const {intl,authStore, userStore, conversationStore, languageStore} = props;
    const conversationListRef = useRef(null);

    useEffect(() => {
        const conversationListElement = conversationListRef.current;

        const handleScroll = () => infiniteScroll(conversationListElement);

        conversationListElement.addEventListener("scroll", handleScroll, true);

        return () => {
            conversationListElement.removeEventListener("scroll", handleScroll, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const infiniteScroll = (targetElement) => {
        let scrollTop = targetElement ? targetElement.scrollTop : 0;
        let clientHeight = targetElement ? targetElement.clientHeight : 0;
        let scrollHeight = targetElement ? targetElement.scrollHeight : 0;

        if (conversationStore.conversationList.length >= conversationStore.rowsPerPage) {
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                conversationStore.getConversationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, true);
            }
        }
    };

  const getDate = (datetime, duration) => {
      if (datetime === null) {
          return intl.formatMessage({ id: "in_conversation" });
      }

      const now = DATE_UTIL.convertTimeZone(dayjs());
      const startDatetime = DATE_UTIL.convertTimeZone(dayjs.utc(datetime));
      const endDatetime = startDatetime.add(duration, 'second');
      const dayDiff = now.startOf('day').diff(endDatetime.startOf('day'), 'day');

      if (dayDiff === 0) {
          return DATE_UTIL.getTimeZoneDate(endDatetime, 'H:mm');
      } else if (dayDiff === 1) {
          return intl.formatMessage({ id: "yesterday" });
      } else {
          const dayString = intl.formatMessage({ id: "day_index" });
          const monthString = intl.formatMessage({ id: "month_index" });
          const dateFormat = `M${monthString} D${dayString}`;

          if (languageStore.language === 'en') {
              const day = DATE_UTIL.getTimeZoneDate(endDatetime, 'D');
              const month = DATE_UTIL.getTimeZoneDate(endDatetime, 'M');

              return `${month}${monthString} ${day}${dayString}`
          } else {
              return DATE_UTIL.getTimeZoneDate(endDatetime, dateFormat);
          }
      }
  }

  // const handleChangePage = (e, newPage) => {
  //     conversationStore.setPage(newPage);
  //     conversationStore.getConversationList(authStore.loginUser.id, userStore.selectedDevice.deviceId);
  // }

  const handleClickDetail = (dialogId) => {
      conversationStore.setType('detail');
      conversationStore.getConversation(authStore.loginUser.id, userStore.selectedDevice.deviceId, dialogId);
  }

  return (
    <Root ref={conversationListRef} style={{overflowY: 'scroll', height: window.innerHeight - 300}}>
          {conversationStore.conversationList.map((conversation, index) => (
            <CardBoxStyle key={index} onClick={() => handleClickDetail(conversation.id)}>
              <BadgeStyle variant={conversation.check === false ? "dot" : ""}>
                <Avatar src={AvatarPokoro}></Avatar>
              </BadgeStyle>
              <Box style={{width: '100%'}}>
                <TitleBox>
                  <ListTitle>{conversation.firstMessage}</ListTitle>
                  <DateTextStyle>{getDate(conversation.startDatetime, conversation.duration)}</DateTextStyle>
                </TitleBox>
                <ListContent>{conversation.lastMessage}</ListContent>
              </Box>
            </CardBoxStyle>
          ))}
    </Root>
  );
}

export default injectIntl(inject('authStore', 'userStore', 'conversationStore', 'languageStore')(observer(ConversationContentComponent)));