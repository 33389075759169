import { inject, observer } from "mobx-react";
import { injectIntl } from "react-intl";
import React, { useRef, useState } from "react";
import { Stack, Box, Typography } from "@mui/material";
import BarChartComponent from "./BarChartComponent";
import WordCloudComponent from "../../CommonComponent/Chart/WordCloud";
import OverallReviewComponent from "./OverallReviewComponent";
import { BoxWrap, BoxStyle, BoxTitle, SpeechBubble, ProgressUserBox, ProgressBox, ProgressOtherBox, BoxTitleStyle, NickName } from "./Styled/ReportWeeklyStyled"
import CommonDialog from "../../CommonComponent/CommonDialog";
import { Icon } from '@iconify/react';
import {shareFileToAOS} from "../../../nativebridge/JsToNative";

// 메인 컴포넌트
function ReportWeekly(props) {
  const { intl, authStore, reportStore } = props;
  const weeklyReport = reportStore.weeklyReport;
  const [chartView, setChartView] = useState(false);
  const reportRef = useRef(null);

  function isAOS() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android/i.test(userAgent);
  }

  /* 관심사 데이터 없을 시 '?'로 표시 */
  const topicWordList = weeklyReport.topicWordList || [];
  const getWord = (index) => {
    return topicWordList[index] ? topicWordList[index].word : '?';
  };

  const peerTopicWordList = weeklyReport.peerTopicWordList || [];
  const getPeerWord = (index) => {
    return peerTopicWordList[index] ? peerTopicWordList[index].word : '?';
  };

  const getHorizontalBarSize = (count) => {
    const windowWidth = window.innerWidth;

    let maxCount = 0;
    let minCount = 0;
    if (weeklyReport.content) {
      maxCount = Math.max(weeklyReport.content.userCount, weeklyReport.content.averageCount);
      minCount = Math.min(weeklyReport.content.userCount, weeklyReport.content.averageCount);
    }

    if (count === maxCount) {
      return (windowWidth * 0.5) + 'px';
    } else {
      return (minCount / maxCount * windowWidth * 0.5) + 'px';
    }
  }

  const handleClickShare = () => {
    reportStore.setShareDialogOpen(false);

    if (isAOS()) {
      shareFileToAOS(reportStore.shareData.type, reportStore.shareData.fileName, reportStore.shareData.data);
    } else {
      navigator.share(reportStore.shareData).then(() => console.log("Report share success."));
    }
  }

  const getConversationDiffString = (value) => {
    if (value > 0) {
      return(
        <>
          {intl.formatMessage({ id: 'more_than_data' })}
          <span> {intl.formatMessage({ id: 'conversation_count_more' }, { count: value })}</span>
        </>
      ); //더 많아요
    } else if (value === 0) {
      return (
        <>
          {intl.formatMessage({ id: 'same_data2' })}
          <span> {intl.formatMessage({ id: 'time_same' })}</span>
        </>
      ); // 같아요.
    } else {
      return (
        <>
          {intl.formatMessage({ id: 'more_than_data' })}
          <span> {intl.formatMessage({ id: 'conversation_count_less' }, { count: Math.abs(value) })}</span>
        </>  //적어요
    )}
  }


  const getContentDiffString = (value) => {
    if (value > 0) {
      return(
        <>
          {intl.formatMessage({ id: 'more_than_data' })}
          <span> {intl.formatMessage({ id: 'book_count_more' }, { count: value })}</span>
        </>
      ); //더 많아요
    } else if (value === 0) {
      return (
        <>
          {intl.formatMessage({ id: 'same_data2' })}
          <span> {intl.formatMessage({ id: 'time_same' })}</span>
        </>
      ); // 같아요.
    } else {
      return (
        <>
          {intl.formatMessage({ id: 'more_than_data' })}
          <span> {intl.formatMessage({ id: 'book_count_less' }, { count: Math.abs(value) })}</span>
        </>  //적어요
    )}
  }

  const getUsageTimeDiffString = (value) => {
    if (value > 0) {
      return(
        <>
          {intl.formatMessage({ id: 'more_than_data' })}
          <span> {intl.formatMessage({ id: 'time_more' }, { count: value })}</span>
        </>
      ); // 분 더 길어졌어요
    } else if (value === 0) {
      return (
        <>
          {intl.formatMessage({ id: 'same_data' })}
          <span> {intl.formatMessage({ id: 'time_same' })}</span>
        </>
      ); // 같아요.
    } else {
      return (
        <>
          {intl.formatMessage({ id: 'more_than_data' })}
          <span> {intl.formatMessage({ id: 'time_less' }, { count: Math.abs(value) })}</span>
        </> // 분 줄어들었어요
    )}
  };
  
  const getFaceIcon = (scoreDifference) => {
    if (scoreDifference > 0) {
      return "emojione:smiling-face-with-heart-eyes";
    } else if (scoreDifference === 0) {
      return "emojione:neutral-face";
    } else {
      return "emojione:loudly-crying-face";
    }
  };

    // 점수 차이 변수 정의 추가
    const scoreDifference = weeklyReport.conversation
    ? weeklyReport.conversation.diffFromAverage
    : 0;

  return (
    <>
      <div ref={reportRef}>
        <BoxWrap>
          {/* 대화량 */}
          <BoxStyle>
            <BoxTitle>
              <Typography variant="subtitle1">{intl.formatMessage({ id: "conversation_volume" })}{/* 대화량 */}</Typography>
              <Typography variant="h6" mb={0.5}>

                <React.Fragment>
                  {intl.formatMessage({ id: "this_week_pokoro_talk" })} {/* 이번주에는 포코로와 */}
                  {weeklyReport.content ? (
                    intl.formatMessage({ id: "conversation_count" }, { count: weeklyReport.conversation ? weeklyReport.conversation.userCount : 0 }) /* 회 대화했어요. */
                  ) : (
                    intl.formatMessage({ id: "no_data_word" }) /* 대화 활동이 없어요. */
                  )}
                </React.Fragment>
              </Typography>

              {weeklyReport.content ? (
              <Typography variant="body1">
                <Icon icon={getFaceIcon(scoreDifference)} />&nbsp;
                {intl.formatMessage({ id: "average_talk" })}{/* 또래 평균 */}
                {weeklyReport.conversation ? getConversationDiffString(weeklyReport.conversation.diffFromAverage) : getConversationDiffString(0)}
                {/* 보다, 와, 많아요, 적어요 */}
              </Typography>
                ) : (<></>)}
            </BoxTitle>

            <Stack flexDirection='row' alignItems='center' justifyContent='center'>
              <SpeechBubble count={weeklyReport.conversation ? weeklyReport.conversation.userCount : 0}>
                <Stack alignItems="center" spacing={0.5}>
                  <NickName className="body1">{authStore.loginUser.nickname}</NickName>
                  <Typography variant="h6" sx={{marginTop:'-4px!important' }}>
                    {weeklyReport.conversation ? weeklyReport.conversation.userCount : 0}
                    {intl.formatMessage({ id: "count_unit" })} {/* 회 */}
                  </Typography>
                </Stack>
              </SpeechBubble>
              <SpeechBubble className="other" count={weeklyReport.conversation ? weeklyReport.conversation.averageCount : 0}>
                <Stack alignItems="center" spacing={0.5}>
                  <Typography variant="body2">
                    {intl.formatMessage({ id: "peer_group" })}{/* 또래 */}
                  </Typography>
                  <Typography variant="h6" sx={{ fontSize: '1.125rem', marginTop:'-4px!important'}}>
                    {weeklyReport.conversation ? weeklyReport.conversation.averageCount : 0}
                    {intl.formatMessage({ id: "count_unit" })}{/* 회 */}
                  </Typography>
                </Stack>
              </SpeechBubble>
            </Stack>
          </BoxStyle>

          {/* 독서량 */}
          <BoxStyle>
            <BoxTitle>
              <Typography variant="subtitle1" style={{ color: "#FF993C" }}>
                {intl.formatMessage({ id: "book_reading" })}{/* 독서량 */}
              </Typography>

              <Typography variant="h6" mb={0.5}>
                <React.Fragment>
                  {intl.formatMessage({ id: "this_week_pokoro_books" })} {/* 이번주에는 포코로와 */}
                  {weeklyReport.content ? (
                    intl.formatMessage({ id: "book_count" }, { count: weeklyReport.content.userCount }) /* 권의 책을 읽었어요. */
                  ) : (
                    intl.formatMessage({ id: "no_data_word" }) /* 대화 활동이 없어요. */
                  )}
                </React.Fragment>
              </Typography>

              {weeklyReport.content ? (
              <Typography variant="body1">
                <Icon icon={getFaceIcon(scoreDifference)} />&nbsp;
                {intl.formatMessage({ id: "average_books" })}{/* 또래 평균 */}
                {weeklyReport.content ? getContentDiffString(weeklyReport.content.diffFromAverage) : getContentDiffString(0)}
                {/* 보다, 와, 많아요, 적어요 */}
              </Typography>
                ) : (<></>)}
            </BoxTitle>
            <Box>
              <ProgressUserBox mb={1}>
                <NickName>{authStore.loginUser.nickname}</NickName>
                <ProgressBox width={getHorizontalBarSize(weeklyReport.content ? weeklyReport.content.userCount : 0)} />
                <Typography variant="body2">{weeklyReport.content ? weeklyReport.content.userCount : 0}</Typography>
              </ProgressUserBox>
              <ProgressOtherBox>
                <NickName>
                  {intl.formatMessage({ id: "peer_group" })}{/* 또래 */}
                </NickName>
                <ProgressBox width={getHorizontalBarSize(weeklyReport.content ? weeklyReport.content.averageCount : 0)} />
                <Typography variant="body2">{weeklyReport.content ? weeklyReport.content.averageCount : 0}</Typography>
              </ProgressOtherBox>
            </Box>
          </BoxStyle>

          {/* 이용시간 */}
          <BoxStyle>
            <BoxTitle>
              <Typography variant="subtitle1" style={{ color: "#4CA515" }}>
              {intl.formatMessage({ id: "talk_time" })} {/* 이용시간 */}
              </Typography>

              <Typography variant="h6" mb={0.5}>
              <React.Fragment>
                  {intl.formatMessage({ id: "this_week_pokoro_minutes" })} {/* 이번주에는 포코로와 */}
                  {weeklyReport.content ? (
                    intl.formatMessage({ id: "talk_minutes" }, {count: weeklyReport.usageTime ? Math.round(weeklyReport.usageTime.thisWeek / 60) : 0 }) /* 분 얘기했어요. */
                  ) : (
                    intl.formatMessage({ id: "no_data_word" }) /* 대화 활동이 없어요. */
                  )}
                </React.Fragment>
              </Typography>

              {weeklyReport.content ? (
              <Typography variant="body1">
                <Icon icon={getFaceIcon(scoreDifference)} />&nbsp;
                {intl.formatMessage({ id: "last_week" })}{/* 지난주 */}
                {getUsageTimeDiffString(weeklyReport.usageTime ? Math.round((weeklyReport.usageTime.thisWeek - weeklyReport.usageTime.lastWeek) / 60) : 0)}
                {/* 보다, 와, 많아요, 적어요 */}
              </Typography>
                ) : (<></>)}

            </BoxTitle>
            <BarChartComponent intl={intl}
              thisWeekUseTime={weeklyReport.usageTime ? Math.round(weeklyReport.usageTime.thisWeek / 60) : 0}
              lastWeekUseTime={weeklyReport.usageTime ? Math.round(weeklyReport.usageTime.lastWeek / 60) : 0}
            />
          </BoxStyle>

          {/* 관심사 */}
          <BoxStyle>
            <BoxTitle>
              <Typography variant="subtitle1" style={{ color: "#868AFF" }}>
                {intl.formatMessage({ id: "interests" })} {/* 관심사 */}
              </Typography>

            
                <React.Fragment>
                  <Typography variant="h6" mb={0.5}>
                    {topicWordList.length > 0 ? (
                      intl.formatMessage({ id: "most_interested_in" }, { target: getWord(0) }) /* 가장 관심을 가지고 있어요. */
                    ) : (
                      intl.formatMessage({ id: "no_data_word" }) /* 대화 활동이 없어요. */
                    )}
                  </Typography>
       
                  {topicWordList.length > 0 ? (
                    <Typography variant="body1">
                      {intl.formatMessage({ id: "recent_interest" })} {/* 요즘 */}
                      {intl.formatMessage({ id: "talks_increased_about" }, { target: <b>{getWord(0)}</b> })} {/* 관련한 대화가 크게 늘었어요. */}
                      
                      {topicWordList.length > 2 && (
                        <React.Fragment>
                          {intl.formatMessage({ id: "next_most_interest" })}&nbsp;{/* 그 다음으로는 */}
                          {intl.formatMessage({ id: "curious_about" }, { target: `${getWord(1)}, ${getWord(2)}` })} {/* 궁금증을 가지고 있어요 */}
                        </React.Fragment>
                      )}
                    </Typography>
                  ) : (
                    <Typography variant="body1">
                      {intl.formatMessage({ id: "nodata_interests" })} {/* 포코로와 대화하면 관심있는 키워드를 모아서 볼 수 있습니다. */} 
                    </Typography>
                  )}
                </React.Fragment>
  
            </BoxTitle>
           
              <Box className="wordcloud" >
                <WordCloudComponent wordList={weeklyReport.topicWordList ? weeklyReport.topicWordList : []} />
              </Box>
              {topicWordList.length > 0 ? (
              <>
              <BoxStyle className="otherChild">
                <BoxTitle>
                    <Typography variant="h7">
                      {intl.formatMessage({ id: "peer_kids_interest" })}&nbsp;{/* 또래 아이들은 */}
                      {intl.formatMessage({ id: "most_interest_in" }, { target: <b>{getPeerWord(0)}</b> })}
                      {/* 에 대해 제일 관심이 많아요. */}
                    </Typography>
                </BoxTitle>
                <Box className="wordcloud" >
                  <WordCloudComponent wordList={weeklyReport.peerTopicWordList ? weeklyReport.peerTopicWordList : []} />
                </Box>
              </BoxStyle>
              </>
            ) : (
              <></>
            )}
          </BoxStyle>
        </BoxWrap>


         {weeklyReport.review && (
            <Box>
              <BoxTitleStyle>
                {intl.formatMessage({ id: "pokoro_overview" })}{/* 포코로의 총평 */}
              </BoxTitleStyle>
              <OverallReviewComponent reportRef={reportRef} chartView={chartView} setChartView={setChartView} />
            </Box>
          )}
        <CommonDialog
          open={reportStore.shareDialogOpen}
          title={intl.formatMessage({ id: "share_report" })}
          subText={
            <span>
              {intl.formatMessage({ id: "share_report_prompt" })}
              {/* 리포트를 공유하시겠습니까? */}
            </span>
          }
          LeftButtonText={intl.formatMessage({ id: "dialog_NoButton" })}
          RightButtonText={intl.formatMessage({ id: "dialog_YesButton" })}
          onClick1={() => reportStore.setShareDialogOpen(false)}
          onClick2={handleClickShare}
        />
      </div>
    </>
  );
}

export default injectIntl(inject('authStore', 'loadingStore', 'reportStore')(observer(ReportWeekly)));
