import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import {
  CardBoxStyle,
  DateTextStyle,
  ListContent,
  ListTitle,
  TitleBox,
} from "./Styled/PronunciationListStyled";
import {Header, ReportBox} from '../Styled/SubPageStyled'
import { inject, observer } from "mobx-react";

import PronunciationListDetailComponent from "./PronunciationListDetail";
import { Icon } from '@iconify/react';
import {DATE_UTIL} from "../../../common/util/date.util";
import dayjs from "dayjs";

function PronunciationList(props) {
  const { intl, authStore, userStore, pronunciationStore } = props;
  const [expandedItem, setExpandedItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => infiniteScroll();
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const infiniteScroll = () => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let clientHeight = window.innerHeight;
    let scrollHeight = document.documentElement.scrollHeight;

    if (pronunciationStore.pronunciationList.length >= pronunciationStore.rowsPerPage) {
      if (scrollTop + clientHeight >= scrollHeight) {
        pronunciationStore.getPronunciationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, true);
      }
    }
  };

  const handleClickDetail = (event, pronunciationId) => {
    if (!event.target.closest(".sound-button")) {
      setExpandedItem(expandedItem === pronunciationId ? null : pronunciationId);
      pronunciationStore.cancelAudio();
    }
  }

  const getFaceIcon = (score) => {
    if (score === 100) {
      return "emojione:smiling-face-with-heart-eyes";
    } else if (score >= 81) {
      return "emojione:face-blowing-a-kiss";
    } else if (score >= 61) {
      return "emojione:beaming-face-with-smiling-eyes";
    } else if (score >= 41) {
      return "emojione:grinning-face-with-sweat";
    } else if (score >= 21) {
      return "emojione:sad-but-relieved-face";
    } else {
      return "emojione:loudly-crying-face";
    }
  };

  const getPronunciationDatetime = (datetime) => {
    return DATE_UTIL.convertUTCToTimeZone(datetime, DATE_UTIL.FORMAT.AMPM_FORMAT);
  }

  return (
    <ReportBox>
      <Header>
        <h3><Icon icon={'flat-color-icons:todo-list'} />{intl.formatMessage({ id: "pronunciation_sentence_list" })}</h3>
      </Header>
      {pronunciationStore.pronunciationList.map((pronunciation, index) => (
          <CardBoxStyle key={index} onClick={(event) => handleClickDetail(event, pronunciation.pronunciationId)}>
            <>
              <TitleBox>
                <ListTitle>{pronunciation.sentence}</ListTitle>
              </TitleBox>
              <ListContent>
                <div className="scoreTitle">
                  <div className="score">{intl.formatMessage({ id: "overall_score" })}: 
                    <strong>{pronunciation.pronScore}{intl.formatMessage({ id: "score" })}</strong>
                    <Icon icon={getFaceIcon(pronunciation.pronScore)} />
                    </div>
                  <DateTextStyle>{getPronunciationDatetime(pronunciation.createdDatetime)}</DateTextStyle>
                </div>
              </ListContent>
              {expandedItem === pronunciation.pronunciationId && (
                  <PronunciationListDetailComponent pronunciation={pronunciation}/>
              )}
            </>
          </CardBoxStyle>
      ))}
    </ReportBox>
  );
}

export default injectIntl(inject('authStore', 'userStore', 'pronunciationStore')(observer(PronunciationList)));
