import React from "react";
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';

function serviceEN(props) {
  return (
    <Container>
      <Typography variant="h4" mb={4} gutterBottom>
        Pokoro Terms of use
        <List>
          <ListItem>
            <ListItemText
              primary="Effective Date:"
              secondary="19 November 2024"
            />
          </ListItem>
        </List>
      </Typography>
      <Typography paragraph>
      Welcome to Pokoro, a product of NeoLAB Convergence Inc. ("Company" or "Pokoro"). These Terms of Use ("Terms") govern your use of Pokoro services, including any hardware, software, and related products provided by the Company. By accessing or using Pokoro, you agree to comply with these Terms. Please read them carefully.
      </Typography>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        1. Purpose
        </Typography>
        <Typography paragraph>
        These Terms set forth the rights, obligations, and responsibilities of NeoLAB Convergence Inc. and the users ("Users") of Pokoro services. They ensure that the services are used safely, effectively, and in compliance with applicable laws. For users under 13 years of age (or the equivalent minimum age of their respective jurisdiction), parental or legal guardian consent is required for using Pokoro’s services.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        2. Definitions
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="User: "
              secondary="Any individual or entity who agrees to these Terms and uses the services provided by the company."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Service:"
              secondary="All services provided by the Company, including the Pokoro device, related softwrem and any auxiliary services."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Account:"
              secondary="A unique indentifier created by the User to access the Services, consisting of a combination of letters, numbers, or special characters."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Account Information:"
              secondary="The User's account details, including thir account ID, pasword, any other relevant information."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Password:"
              secondary="A combination of letters, numbers, or special characters chosen by the User to verify their identity and protect their information."
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        3. Provision of Company Information
        </Typography>
        <Typography paragraph>
        The Company will display the following information within the Services:
        <List>
            <ListItem>
                <ListItemText primary="Privacy Policy"/>
            </ListItem>
            <ListItem>
                <ListItemText primary="Terms of Use"/>
            </ListItem>
        </List>
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
       4. Amendments to Terms
        </Typography>
        <Typography paragraph>
        The company reserves the right to amend these Terms as necessary. Users will be notified of any changes through th Services or other communication methods before the amendments take effect. Continued use of the Services after the effective date of any chages constitutes acceptance of the new Terms. Users who do not agree with the amended Terms may terminate their use of the Services.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        5. Service Policies
        </Typography>
        <Typography paragraph>
        The Company may establish and modify operational policies necessary to implement these Terms. These policies will be made available to Users within the Services. Any significant changes to service policies that affect Users' rights or obligations will be communicated to Users in advance, following the amendment procedures outlined in Section 4.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        6. Acceptance and Restrictions of Service Use
        </Typography>
        <Typography paragraph>
        Users must agree to these Terms to use the Services. By creating an account or using the Services, Users are deemed to have accepted these Terms. For Users under 13 (or the equivalent minimum age of their respective jurisdiction), parental or legal guardian’s consent is required. The Company may deny or cancel a User's service request in the following cases:
        <List>
          <ListItem>
            <ListItemText 
              primary="If the User fails to meet the necessary requirements for service use."/>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="If the User attempts to access the Services from a country where the Company does not provide the Services."/>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="If the Company determines that the User's service request is inappropriate for other reasons, including attempts to misuse the Services."/>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="If a User uses or used a fake name or the name or information of another person when using the Services"/>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="If a User uses the Services with the purpose of undermining the social well-being, order, and public moral or to conduct any act prohibited by applicable laws"/>
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="If a User is re-applying for the Services after being denied Services due to violating this Terms of Service"/>
          </ListItem>
          <ListItem>
            <ListItemText 
            primary="If a User is under the age of 13 (or the equivalent minimum age of their respective jurisdiction) and has not obtained consent of his or her parent or legal guardian"/>
          </ListItem>
          <ListItem>
            <ListItemText 
            primary="If the Company lacks facilities, have instable network, have difficulty in supporting certain mobile devices, or have technical difficulties"/>
          </ListItem>
          <ListItem>
            <ListItemText 
            primary="If there is disruption in the Services or in the Service fee or payment method"/>
          </ListItem>
          <ListItem>
            <ListItemText 
            primary="If there is an occurrence of an event that is beyond the control of the Company such as war, accident, natural disaster, or any equivalent national emergency"/>
          </ListItem>
          <ListItem>
            <ListItemText 
            primary="If it is difficult for the Company to accept the service request due to any other just reason"/>
          </ListItem>
        </List>
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          7. User Responsibilities
        </Typography>
        <Typography paragraph>
          Users are required to adhere to the following responsibilities while using the Services:
          <List>
            <ListItem>
              <ListItemText
                primary="Accurate Information:"
                secondary="Users must provide accurate and truthful information when registering or updating their account information."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Account Security:"
                secondary="Users are responsible for maintaining the confidentiality of their account and password. The Company is not liable for any damages resulting from the User's failure to protect their account information."
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary="Prohibited Activities:"
                secondary={
                  <>
                  <p>Users shall not engage in any activity under the following subparagraphs:
                  </p>
                  <ul style={{listStyle:'circle', marginLeft:16}}>
                    <li>infringe upon the intellectual property rights, privacy, or other rights of the Company or third parties </li>
                    <li>engage in any activities that could harm the reputation or operations of the Company or violate any applicable laws or ethical standards</li>
                    <li>infringe upon the intellectual property rights, privacy, or other rights of the Company or third parties use false information or other people’s information when applying for the Service or using the Service</li>
                    <li>change any information provided by the Company or engage in an act that impairs the Service</li>
                    <li>produce, distribute, or use Services with devices that have not been provided or approved by the Company</li>
                    <li>intentionally transfer, distribute, reproduce, disassemble, imitate, or modify the Service or the Pokoro device by reverse engineering, decompiling, disassembling or by any other processing activities</li>
                    <li>granting access to one’s account to a third party, such as lending or transferring the User’s Account.</li>
                  </ul>
                  </>
                }
              />
            </ListItem>
          </List>
        </Typography>
        <Typography paragraph>
        In the event the User breaches these Terms, including, but not limited to, engaging in any prohibited activities outlined above, the Company may, at its sole discretion and without prior notice or liability, take immediate corrective actions. Such actions may include, but are not limited to, the temporary or permanent suspension of the User’s access to the Service, the deletion of the User’s account and any associated data or content, and the pursuit of any additional remedies available under applicable law.
        </Typography>
        <Typography paragraph>
        In cases involving suspected illegal activities, the Company may report the User’s conduct to relevant law enforcement or regulatory authorities. Additionally, the User may be held financially liable for any damages, losses, or expenses incurred by the Company or other Users directly or indirectly as a result of the User’s prohibited conduct. The Company’s rights and remedies under this provision are cumulative, meaning they are in addition to, and do not limit, any other rights or remedies available to the Company under this Agreement or applicable law.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
          8. Service Provision and Modification
        </Typography>
        <Typography paragraph>
        Upon acceptance of a User's request, the Company will provide access to the Services. The Company reserves the right to modify, update, enhance, suspend, or permanently discontinue the Services, including any related software and firmware, in whole or in part, at any time, for any reason and without liability. Such actions may be necessary for various reasons, including but not limited to operational, technical, or business needs, changes in business strategy, regulatory or legal requirements, technological updates, or other reasons. In the event of a complete termination of the Services, the Company will provide notice on its website at least 30 days in advance
        </Typography>
        <Typography paragraph>
        Where significant modifications to the Services are planned or where the Company plans to suspend or permanently discontinue the Services, the Company will endeavor to provide advance notice to Users where practicable, to minimize any inconvenience. Users acknowledge and agree that the Company shall bear no liability for any direct, indirect, incidental, consequential, or special damages resulting from such actions.
        </Typography>
        <Typography paragraph>
        Continued use of the Services following any modification, update, or enhancement constitutes the User’s acceptance of those changes. If a User does not agree to a modification or update, their sole and exclusive remedy shall be to discontinue use of the Services.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        9. Use of Cookies and Tracking Technologies
        </Typography>
        <Typography paragraph>
        The Company is committed to protecting Users' personal information in accordance with applicable privacy laws, including the General Data Protection Regulation (GDPR), and others. For users under 13 (or the equivalent minimum age of their respective jurisdiction), personal information is collected, used, and shared in strict compliance with COPPA, GDPR, and other relevant child protection laws. Parents or legal guardians have the right to review, modify, or delete their child’s personal information at any time and can contact our support team to exercise these rights. The management of personal information is detailed in the Company’s Privacy Policy, which Users are encouraged to review.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        10. Intellectual Property Rights
        </Typography>
        <Typography paragraph>
        All intellectual property rights related to the Services, including proprietary technology such as Ncode™ technology and the Pokoro AI Guidance Engine, are owned by the Company. Users are prohibited from reverse engineering, reproducing, or using any part of the Services for commercial purposes without the Company’s prior written consent. This Article will remain valid during the Company’s operation of the Services and will continue to apply even after the User ceases to use the Services or their Account is terminated pursuant to these Terms. 
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        11. Termination of Service and Account
        </Typography>
        <Typography paragraph>
        Users may terminate their account and discontinue using the Services at any time. Upon termination, all data related to the User’s account will be deleted, unless otherwise specified in the Privacy Policy. The Company reserves the right to terminate a User's account if the User violates these Terms or any applicable laws.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        12. Limitation of Liability
        </Typography>
        <Typography paragraph>
        The Company shall not be liable for any interruptions or failures in the provision of the Services caused by events beyond its reasonable control, including but not limited to natural disasters, wars, technical failures, or actions by third parties, or for services provided free of charge. The Company is also not responsible for any damages resulting from the User's misuse of the Services or failure to follow these Terms.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        13. Disclaimer Regarding Misuse of the Device
        </Typography>
        <Typography paragraph>
        The Pokoro device and services are intended for educational and informational purposes only and are designed to promote positive learning experiences. The Company expressly disclaims any liability for any actions taken by Users that are harmful to themselves or others. The Company does not provide medical, psychological, or crisis intervention services. If you or someone you know is experiencing thoughts of self-harm, depression, anxiety, or any other mental health crisis, it is crucial to seek immediate help from qualified professionals. The Company strongly encourages Users to contact local emergency services, a mental health professional, or a crisis intervention hotline for assistance. By using the Pokoro device and services, Users acknowledge and agree that the Company is not responsible for any consequences resulting from the misuse of the device or services in ways that are harmful to the User or others.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        14. Access and Control by Legal Guardians
        </Typography>
        <Typography paragraph>
        Parents or legal guardians can access their child’s account information, monitor usage, and request the modification or deletion of their child’s data by contacting our support team. Parents should supervise their child’s use of the services to ensure safe and appropriate usage.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        15. Age Verification
        </Typography>
        <Typography paragraph>
        The Company employs age verification processes to ensure compliance with international laws regarding the protection of children’s data. If it is discovered that a User under 13 (or the equivalent minimum age of their respective jurisdiction) has created an account without the consent of his or her parent or legal guardian, the Account will be suspended, and the data will be immediately deleted unless the Company is legally permitted to retain such data in accordance with relevant laws.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        16. Use of Ncode™ Technology and AI Interactions
        </Typography>
        <Typography paragraph>
        Pokoro utilizes proprietary Ncode™ technology that enables seamless interaction between physical paper and digital content. By scanning Ncode™ printed paper, Pokoro can trigger linked audio files or AI-generated prompts, creating an interactive learning environment. Users must use only authorized Ncode™ products with Pokoro. The Company is not liable for any malfunction or incorrect operation resulting from the use of unauthorized or modified Ncode™ products.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        17. Generative AI and User Interaction
        </Typography>
        <Typography paragraph>
        The device uses generative AI, including speech-to-text (STT) and text-to-speech (TTS) technologies, combined with the Pokoro AI Guidance Engine, to provide age-appropriate, contextually relevant responses. The AI interactions are designed to be intellectually stimulating and suitable for the child’s age. The User acknowledges that the AI's responses are generated based on algorithms and the data provided, and while designed to be educational, the content may vary. The Company does not guarantee that all AI-generated content will meet the specific educational goals of the User or be suitable for any particular purpose. Additionally, the Company disclaims any responsibility for the outcomes or effectiveness of the AI-generated content in addressing specific learning needs or expectations.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        18. Control and AI Configuration by Legal Guardians
        </Typography>
        <Typography paragraph>
        Parents or guardians can configure the language and content parameters for Pokoro, ensuring that the AI operates within a context suitable for the child. Parents are responsible for setting these configurations appropriately to align with their child’s learning needs. The Company provides tools for monitoring and managing these settings but is not liable for any misconfiguration by the User. Parents or guardians are encouraged to periodically review and update these configurations to ensure they continue to align with their child’s developmental and learning needs. In addition to configuring these settings, parents are encouraged to actively supervise their child’s use of the AI to ensure safe and appropriate engagement with the content. The Company is not liable for any unintended outcomes or content provided by the AI, even if the parameters are correctly configured by the User.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        19. Data Privacy and AI Logging
        </Typography>
        <Typography paragraph>
        Pokoro’s AI engine logs interactions to improve service quality and provide insights to parents or guardians. These logs may include voice recordings, usage patterns, and AI-generated responses. All data collected is processed in compliance with international data protection laws, including GDPR and COPPA. Users can access, review, and delete these logs as per the Privacy Policy, ensuring control over the data collected.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        20. Liability for AI Responses
        </Typography>
        <Typography paragraph>
        The AI-generated responses are intended for educational purposes and are based on the input provided by the User. The Company is not responsible for any unintended consequences or interpretations of these responses. The User acknowledges that while the AI is designed to provide accurate and educationally valuable content, it is still a machine learning model that may not always perform as expected or always provide factually accurate answers.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        21. Updates and Modifications to AI and Ncode™ Technology
        </Typography>
        <Typography paragraph>
        The Company reserves the right to update and modify the AI algorithms, Ncode™ technology, and software used by Pokoro to improve performance and security. These updates may be automatically applied to ensure that Pokoro continues to function optimally. Users will be notified of significant changes that may affect the operation of the device.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        22. Compliance with Educational Standards
        </Typography>
        <Typography paragraph>
        While Pokoro is designed to support and enhance learning, it is not a substitute for formal education. The Company makes no guarantees that the use of Pokoro will result in specific educational outcomes or achievements. Users should use Pokoro as a supplement to traditional educational methods.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        23. Support and Troubleshooting
        </Typography>
        <Typography paragraph>
        In the event of technical issues, Users should contact the Company’s support team for assistance via [email address]. The Company provides troubleshooting guides and support services to help Users resolve any problems. However, the Company is not responsible for any loss of data or interruption of service resulting from User actions or failure to follow the provided guidelines.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        24. Purchase of Paid Services
        </Typography>
        <Typography paragraph>
        One-Time Purchases: Users may purchase one-time services or features through the Pokoro platform. Unless otherwise permitted by any relevant laws, these purchases are non-refundable and may not be canceled once the transaction is complete. Users agree to provide accurate payment information and acknowledge that they are responsible for all charges associated with the transaction.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        25. Payment and Billing
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="25.1 Payment Authorization:"
              secondary="By providing payment information, users authorize the Company to charge the designated payment method for all applicable fees. Users are responsible for ensuring that the payment method is valid and has sufficient funds or credit to complete the transaction. The Company reserves the right to suspend or terminate access to paid services if payment is declined or fails for any reason."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="25.2 Price Changes:"
              secondary="The Company reserves the right to adjust pricing for any paid services at any time. Users will be notified of any price changes in advance and continued use of the Services after a price change constitutes acceptance of the new pricing. In certain jurisdictions, including South Korea, explicit consent from users is required by law for any price adjustments. In these regions, Users will be asked to provide their consent before the new pricing takes effect. If a User does not provide the required consent, access to the Services will be discontinued."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="25.3 Taxes:"
              secondary="Users are responsible for any taxes that may apply to their purchase of Services, including VAT, sales tax, or other local taxes. The Company will collect taxes where required by law and will disclose the tax amount during the transaction process."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="25.4 Billing Disputes:"
              secondary="Any billing disputes must be raised within 30 days of the transaction. Users can contact customer support to resolve any issues. The Company will investigate and, if necessary, issue adjustments or refunds as appropriate. The Company’s decision on billing disputes is final."
              />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        26. Transactions and Order Fulfillment
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="26.1 Transaction Acceptance: "
              secondary="All orders placed through the Pokoro platform are subject to acceptance by the Company. The Company reserves the right to refuse any order or limit the quantity of items purchased for any reason, including but not limited to, stock availability, errors in pricing or product descriptions, or suspected fraudulent activity."
              />
          </ListItem>
          <ListItem>
            <ListItemText 
              primary="26.2 Digital Content and Services:"
              secondary="For digital content and services, the user acknowledges that they have no right to cancel once the delivery of the digital content or service has begun, and by agreeing to the purchase, they lose the right to a withdrawal period."
              />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        27. Contact Information
        </Typography>
        <Typography paragraph>
        For any questions or concerns regarding these Terms or the use of the Services, please contact our support team at<a href="mailto:contact@neolab.net.">contact@neolab.net.</a>
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        28. Governing Law 
        </Typography>
        <Typography paragraph>
        These Terms are governed by and construed in accordance with the laws of Republic of Korea, without giving effect to any provisions relating to conflict of laws that would require the laws of another jurisdiction to apply. 
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h6" gutterBottom>
        29. Dispute Resolution
        </Typography>
        <Typography paragraph>
        BY AGREEING TO THESE TERMS, YOU AGREE TO BE EXCLUSIVELY BOUND BY THE FOLLOWING DISPUTE RESOLUTION MECHANISM. ANY DISPUTES ARISING OUT OF OR IN CONNECTION WITH THIS CONTRACT SHALL BE FINALLY SETTLED BY ARBITRATION IN ACCORDANCE WITH THE INTERNATIONAL ARBITRATION RULES OF THE KOREAN COMMERCIAL ARBITRATION BOARD. THE NUMBER OF ARBITRATORS SHALL BE THREE. THE SEAT, OR LEGAL PLACE, OF ARBITRAL PROCEEDINGS SHALL BE SEOUL, THE REPUBLIC OF KOREA. THE LANGUAGE TO BE USED IN THE ARBITRAL PROCEEDINGS SHALL BE ENGLISH.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h6" gutterBottom>
        30. Miscellaneous
        </Typography>
        <Typography paragraph>
        If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will continue to be valid and enforceable. The Company may transfer its rights and obligations under these Terms to a third party without the User's consent, provided that such transfer does not adversely affect the User's rights.
        </Typography>
      </Box>
    </Container>
  );
}

export default serviceEN;
