import {Stack, Box} from "@mui/material";
import { styled } from "@mui/material/styles";

export const WrapBox = styled(Stack)(() => ({
  gap: '1rem',
  padding: '1.5rem 1rem',
  background: '#f8f8f8',
}));
export const WordListBox = styled(Box)(() => ({
  border:'1px solid #f0f1f5', 
  padding: 8, 
  borderRadius: 2, 
  background: '#fbfcfe',
  whiteSpace:'nowrap',
  overflow:'scroll'
}))

export const WordList = styled(Box)(() => ({
  textAlign:'center',
  display:'inline-block',
  position:'relative',
  '&:after':{
    content:'""',
    position:'absolute',
    width:1,
    height: 40,
    top: '50%',
    right:-10,
    transform:'translateY(-50%)',
    background:'#ddd'
  },
  '&:last-child:after':{
    content:'none'
  }
}))

export const ListenPronunciation = styled(Stack)(()=> ({
  '& .Title':{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  }
}))


