import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/zh';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import minMax from 'dayjs/plugin/minMax';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import weekOfYear from 'dayjs/plugin/weekOfYear';


dayjs.locale('ko');
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(minMax);
dayjs.extend(weekday);
dayjs.extend(duration);
dayjs.extend(advancedFormat);
dayjs.extend(isTomorrow);
dayjs.extend(weekOfYear);

class DateUtil {
    constructor() {
        this.timeZone = dayjs.tz.guess();
        this.language = navigator.language;

        this.FORMAT = {
            DEFAULT_FORMAT: 'YYYY-MM-DD HH:mm',
            FULL_FORMAT: 'YYYY-MM-DD HH:mm:ss',
            DATE_FORMAT: 'YYYY-MM-DD',
            DAY_FORMAT: 'YYYY. MM. DD (ddd)',
            AMPM_FORMAT: 'YYYY. MM. DD. A hh:mm',
            SIMPLE_TIME_FORMAT: 'hh:mm',
            SIMPLE_DATE_AND_TIME_AMPM_FORMAT: 'MM-DD hh:mm A',
            SIMPLE_TIME_AMPM_FORMAT: 'A hh:mm',
            NORMAL_TIME_FORMAT: 'HH:mm:ss',
            DOT_DATE_FORMAT: 'YYYY.MM.DD.',
            SINGLE_DOT_DATE_FORMAT: 'YYYY.M.D.',
            DOT_FORMAT: 'YYYY.M.D. (ddd)',
            SINGLE_DIGIT_DOT_DATE_FORMAT: 'YYYY.M.D.',
            FULL_DATE_DOT_FORMAT: 'YYYY.M.D. HH:mm',
            FULL_DATE_AMPM_DOT_FORMAT: 'YYYY.MM.DD. A hh:mm',
            SIMPLE_DATE_AMPM_DOT_FORMAT: 'MM.DD. hh:mm A',
            SIMPLE_AMPM: 'A',
            DAY: 'DD',
            MONTH: 'MM',
            YEAR: 'YYYY',
            SIMPLE_DAY: 'ddd',
            SINGLE_DIGIT_DOT_DATE_FORMAT_KOREAN: 'YYYY년 M월 D일',
        };
    }

    setLanguage(language) {
        this.language = language;
    }

    getUTCDate(date) {
        return dayjs(date).tz('UTC');
    };

    getTimeDiffWithUTC() {
        let offsetInMinutes = dayjs().utcOffset();

        let hours = Math.floor(offsetInMinutes / 60);
        let minutes = Math.abs(offsetInMinutes % 60);

        return hours + ',' + minutes;
    }

    getTimeZoneDate(date, format) {
        return dayjs(date).tz(this.timeZone).locale(this.language).format(format);
    }

    convertUTCToTimeZone(date, format) {
        return this.getTimeZoneDate(dayjs(date).tz('UTC', true), format);
    }

    convertTimeZone(date) {
        return date.tz(this.timeZone);
    }

    convertLocale(date) {
        return date.locale(this.language);
    }

    getTimeZoneLanguage(date) {
        return date.locale(this.language);
    }
}

export const DATE_UTIL = new DateUtil();
