import React, { useEffect, useState } from "react";

import { Typography, FormControlLabel, Checkbox } from "@mui/material";
import { injectIntl } from "react-intl";

import { ReactComponent as NoeLABLogo } from "../../common/Images/NeoLABLogo.svg";
import { ReactComponent as AppleLogo } from "../../common/Images/AppleLogo.svg";
import { ReactComponent as GoogleLogo } from "../../common/Images/GoogleLogo.svg";
import { ReactComponent as NaverLogo } from "../../common/Images/NaverLogo.svg";
import { ReactComponent as KakaoLogo } from "../../common/Images/KakaoLogo.svg";
import { ReactComponent as PoKoRoIcon } from "../../common/Images/PoKoRoIcon.svg";

import {
  NoeButtonStyle,
  AppleButtonStyle,
  ButtonBox,
  ContentsBox,
  GoogleButtonStyle,
  KakaoButtonStyle,
  NaverButtonStyle,
  Root,
  TextStyle,
  TitleTextStyle,
  RecentlyBox,
} from "./Styled/SignInStyled";
import { inject, observer } from "mobx-react";
import qs from "query-string";
import { useHistory } from "react-router-dom";

import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
import {
  LocalStorageKeepLogin,
  LocalStorageLoginType,
  LocalStorageNdpRefreshToken,
  LocalStorageNdpRefreshTokenExpireDatetime,
  State,
} from "../../stores/AuthStore";
import LogginIn from "./LogginIn";
import dayjs from "dayjs";
import { startlogin } from "../../nativebridge/JsToNative";
import {
  NativeToJsBridge,
  NativeToJsEventName,
} from "../../nativebridge/NativeToJs";
import CommonDialog from "../CommonComponent/CommonDialog";
import {
  LoadingBox,
} from "../Home/Styled/DeviceDataDialogComponentStyled";
import { Loader } from "../CommonComponent/Styled/CommonLoadingStyled";

function SignIn(props) {
  const { intl, authStore, languageStore } = props;
  const history = useHistory();
  const [isLoggingIn, setIsLoggingIn] = useState(false);

  const isWebView = () => {
    console.log("isWebView userAgent=" + window.navigator.userAgent);
    // WebView 일 때만 true, PC 또는 모바일 브라우저는 false
    return /inApp/i.test(window.navigator.userAgent);
  };
  //TODO type 수정
  // const type = "live";
  const type = "live";
  let authServerUrl = "https://ndp-dev.onthe.live:7443";
  // let authServerUrl = "http://localhost:8090";
  if (type === "live") {
    authServerUrl = "https://auth.neolab.net";
  }

  let clientId = "";

  let redirectUri = "https://pokoro-dev.onthe.live:444/loginCheck.html";

  if (isWebView()) {
    redirectUri = "pokorologin://callback";
  } else {
    if (type === "live") {
      redirectUri = "https://board.pokoro.ai/loginCheck.html";
    } else if (type === "staging") {
      redirectUri = "https://pokoro-dev.onthe.live/loginCheck.html";
    } else if (type === "local") {
      redirectUri = "http://localhost:3000/loginCheck.html";
    } else if (type === "dev-app") {
      redirectUri = "https://pokoro-temp.web.app/loginCheck.html";
    }
  }

  useEffect(() => {
    if (authStore.loginCode === "fail") {
      authStore.setLoginCode("");
    } else if (authStore.loginCode !== "") {
      authStore.doLogin(history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStore.loginCode]);

  useEffect(() => {
    console.log(`Sign isWebView = ${isWebView()}`);
    const isKeepLogin = localStorage.getItem(LocalStorageKeepLogin);
    const longinType = localStorage.getItem(LocalStorageLoginType);
    const ndpRefreshToken = localStorage.getItem(LocalStorageNdpRefreshToken);
    const dnpRefreshTokenExpireDatetime = localStorage.getItem(
      LocalStorageNdpRefreshTokenExpireDatetime
    );

    if (
      isKeepLogin === 'true' &&
      longinType !== null &&
      ndpRefreshToken !== null &&
      dnpRefreshTokenExpireDatetime !== null
    ) {
      const today = dayjs();
      if (dayjs(dnpRefreshTokenExpireDatetime).isAfter(today)) {
        authStore.autoLogin(longinType, ndpRefreshToken);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onLogin,
      async (event) => {
        const authcode = event.authcode;
        console.log("=================== authcode=" + authcode);
        // const code = event;
        onLoginSuccess(authcode);
      }
    );

    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onLoginFail,
      async (event) => {
        onLoginFail();
      }
    );

    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onLogin
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onLoginFail
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoginSuccess = (code) => {
    console.log("=== onLoginSuccess. code=" + code);
    authStore.setLoginCode(code);
    setIsLoggingIn(false);
  };

  const onLoginFail = () => {
    console.log("=== onLoginFail.");
    authStore.setLoginCode("fail");
    setIsLoggingIn(false);
    handleClickOpen();
  };

  const login = async (authUrl) => {
    return new Promise((resolve, reject) => {
      const popup = window.open(
        authUrl,
        "_blank",
        "width = 574, height = 890, top = 0, left = 0,scrollbars=no, resizable=no, toolbars=no, menubar=no, popup=yes, location = no, noopener=0"
      );
      if (popup === null) {
        resolve("nullPopup");
      }

      function receiveMessage(event) {
        if (event.origin !== window.location.origin) {
          return;
        }

        if (
          event.data.constructor !== String ||
          event.data.indexOf("login/") === -1
        ) {
          return;
        }

        popup.close();

        const search = event.data.substr(6);
        let { code } = qs.parse(search);
        code = String(code).replace(/[^0-9a-zA-Z]/g, "");
        if (code !== "undefined") {
          code = code.substring(0, 8);
          try {
            if (code) {
              setTimeout(() => {
                authStore.setLoginCode(code);
              }, 100);
              resolve(true);
            }
          } catch (e) {
            resolve(e);
          }
        } else {
          try {
            let { error } = qs.parse(search);
            if (error !== "undefined") {
              setTimeout(() => {
                authStore.setLoginCode("fail");
              }, 100);
              resolve(error);
            } else {
              if (code) {
                setTimeout(() => {
                  authStore.setLoginCode("fail");
                }, 100);
                resolve(false);
              }
            }
          } catch (e) {
            resolve(e);
          }
        }
      }
      window.addEventListener("message", receiveMessage);

      const interval = setInterval(() => {
        if (popup !== null && popup.closed) {
          clearInterval(interval);
          window.removeEventListener("message", receiveMessage);
          if (authStore.loginCode === "") {
            resolve(false);
          }
        }
      }, 500);
    });
  };

  const handleClickLogin = async (loginType) => {
    if (loginType === "NeoLab") {
      authStore.setLoginType("NeoLab");
      clientId = "ioted_android_neolab";
    } else if (loginType === "Google") {
      authStore.setLoginType("Google");
      clientId = "ioted_android_google";
    } else if (loginType === "Apple") {
      authStore.setLoginType("Apple");
      clientId = "ioted_ios_apple";
    } else if (loginType === "Kakao") {
      authStore.setLoginType("Kakao");
      clientId = "ioted_android_kakao";
    } else if (loginType === "Naver") {
      authStore.setLoginType("Naver");
      clientId = "ioted_android_naver";
    }

    setIsLoggingIn(true);
    const authUrl = `${authServerUrl}/oauth/v2/authorize?client_id=${clientId}&response_type=code&scope=openid&redirect_uri=${redirectUri}&lang=${languageStore.language}`;

    console.log("isWebView====" + isWebView());
    if (isWebView()) {
      startlogin(authUrl);
    } else {
      const isLogin = await login(authUrl);

      if (isLogin !== true) {
        console.log("====Login fail. isLogin: " + isLogin);
        if (isLogin === "IsNotSocialUser") {
          handleClickNotSocialOpen();
        } else {
          handleClickOpen();
        }
      }

      setIsLoggingIn(false);
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  const handleChangeKeepLogin = (event) => {
    const checked = event.target.checked;
    localStorage.setItem(LocalStorageKeepLogin, checked);
  };

  const [notSocialOpen, setNotSocialOpen] = React.useState(false);

  const handleClickNotSocialOpen = () => {
    setNotSocialOpen(true);
  };
  const handleClickNotSocialClose = () => {
    setNotSocialOpen(false);
  };

  return (
    <React.Fragment>
      {authStore.loginState === State.Pending ? (
        <LogginIn />
      ) : (
        <Root>
          <ContentsBox>
            <PoKoRoIcon />
            <TextStyle>AI Learning Mate</TextStyle>
            <TitleTextStyle>POKORO</TitleTextStyle>
          </ContentsBox>
          <ButtonBox>
            <NoeButtonStyle
              disableRipple
              onClick={() => handleClickLogin("NeoLab")}
            >
              {localStorage.getItem(LocalStorageLoginType) &&
                localStorage.getItem(LocalStorageLoginType) === "NeoLab" && (
                  <RecentlyBox>
                    {intl.formatMessage({ id: "recent_use" })}
                  </RecentlyBox>
                )}
              <NoeLABLogo />
              <Typography>
                {intl.formatMessage({ id: "neolab_login" })}
              </Typography>
            </NoeButtonStyle>
            <GoogleButtonStyle
              disableRipple
              onClick={() => handleClickLogin("Google")}
            >
              {localStorage.getItem(LocalStorageLoginType) &&
                localStorage.getItem(LocalStorageLoginType) === "Google" && (
                  <RecentlyBox>
                    {intl.formatMessage({ id: "recent_use" })}
                  </RecentlyBox>
                )}
              <GoogleLogo />
              <Typography>
                {intl.formatMessage({ id: "google_login" })}
              </Typography>
            </GoogleButtonStyle>
            <AppleButtonStyle
              disableRipple
              onClick={() => handleClickLogin("Apple")}
            >
              {localStorage.getItem(LocalStorageLoginType) &&
                localStorage.getItem(LocalStorageLoginType) === "Apple" && (
                  <RecentlyBox>
                    {intl.formatMessage({ id: "recent_use" })}
                  </RecentlyBox>
                )}
              <AppleLogo />
              <Typography>
                {intl.formatMessage({ id: "apple_login" })}
              </Typography>
            </AppleButtonStyle>
            {/*<KakaoButtonStyle disableRipple onClick={() => handleClickLogin('Kakao')}>*/}
            {/*    {(localStorage.getItem(LocalStorageLoginType) && localStorage.getItem(LocalStorageLoginType) === 'Kakao') &&*/}
            {/*        <RecentlyBox>{intl.formatMessage({id: "recent_use"})}</RecentlyBox>*/}
            {/*    }*/}
            {/*    <KakaoLogo />*/}
            {/*    <Typography>{intl.formatMessage({id: "kakao_login"})}</Typography>*/}
            {/*</KakaoButtonStyle>*/}
            {/*<NaverButtonStyle disableRipple onClick={() => handleClickLogin('Naver')}>*/}
            {/*    {(localStorage.getItem(LocalStorageLoginType) && localStorage.getItem(LocalStorageLoginType) === 'Naver') &&*/}
            {/*        <RecentlyBox>{intl.formatMessage({id: "recent_use"})}</RecentlyBox>*/}
            {/*    }*/}
            {/*    <NaverLogo />*/}
            {/*    <Typography>{intl.formatMessage({id: "naver_login"})}</Typography>*/}
            {/*</NaverButtonStyle>*/}
            <FormControlLabel
              control={<Checkbox onChange={handleChangeKeepLogin} />}
              label={intl.formatMessage({ id: "stay_logged_in" })}
              sx={{ "& .MuiFormControlLabel-label": { fontSize: "14px" } }}
            />
          </ButtonBox>
        </Root>
      )}

      <CommonDialog
        open={isLoggingIn}
        title={intl.formatMessage({ id: "login_status" })}
        subTextColor={"#909195"}
        childrenBox={
          <div>
            <LoadingBox>
              <Loader style={{ marginBottom: 0 }}>
                <svg className="spinner" viewBox="0 0 48 48">
                  <circle
                    className="path"
                    cx="24"
                    cy="24"
                    r="21.5"
                    fill="none"
                    strokeWidth="5"
                  ></circle>
                </svg>
              </Loader>
            </LoadingBox>
          </div>
        }
        action={false}
      />

      <CommonComfirmDialog
        open={open}
        title={intl.formatMessage({ id: "failed_login" })} /* 로그인 실패 */
        contents={
          <React.Fragment>
            <pre>{intl.formatMessage({ id: "login_error" })}</pre> {/* 알수없는 오류로 인해 로그인에 실패했습니다. 다시 로그인 해주세요. */}
          </React.Fragment>
        }
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })} /* 확인 */
        onClick={handleClickClose}
      />

      <CommonComfirmDialog
        open={notSocialOpen}
        title={intl.formatMessage({ id: "noe_alert" })}
        contents={
          <React.Fragment>
            {intl.formatMessage({ id: "neo_alert_txt" })} {/* 해당 계정은 NeoLAB 로그인 계정입니다.로그인 화면으로 돌아가 NeoLAB으로 로그인해주세요. */}
          </React.Fragment>
        }
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleClickNotSocialClose}
      />

      <CommonComfirmDialog
        open={authStore.loginState === State.Failed}
        title={intl.formatMessage({ id: "failed_login" })}
        contents={
          <React.Fragment>
            <pre>{intl.formatMessage({ id: "login_error" })}</pre> {/* 알수없는 오류로 인해 로그인에 실패했습니다. 다시 로그인 해주세요. */}
          </React.Fragment>
        }
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={() => {
          authStore.setLoginState(State.NotAuthenticated);
        }}
      />

      <CommonComfirmDialog
          open={authStore.loginState === State.Disabled}
          title={intl.formatMessage({ id: "deleted_account" })}
          contents={
            <React.Fragment>
              <pre>{intl.formatMessage({ id: "deleted_account_error" })}</pre> {/* 삭제된 계정입니다. */}
            </React.Fragment>
          }
          buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
          onClick={() => {
            authStore.setLoginState(State.NotAuthenticated);
          }}
      />
    </React.Fragment>
  );
}

export default inject(
  "authStore",
  "languageStore"
)(injectIntl(observer(SignIn)));
