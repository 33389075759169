import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { Typography, Stack, Box, Button} from "@mui/material";
import 'react-circular-progressbar/dist/styles.css';
import {
  WrapBox,
  ListenPronunciation,
  WordList,
  WordListBox
} from "./Styled/PronunciationListDetailStyled";
import { inject, observer } from "mobx-react";
import PronuncuationCircularProgressbar from "../../CommonComponent/Chart/PronunciationCirularProgressbar"
/* Icon */
import {Icon} from '@iconify/react'

function PronunciationListDetailComponent(props) {
  const { intl, authStore, userStore, pronunciationStore, pronunciation } = props;
  const { wordList } = pronunciation;
  const [value, setValue] = useState({accuracyScore: 0, completenessScore: 0, fluencyScore: 0, pronScore: 0});

  useEffect(() => {
    setTimeout(() => {
      setValue(pronunciation);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function isIOS() {
    const userAgent = navigator.userAgent.toLowerCase();
    return /iphone|ipod|ipad/.test(userAgent);
  }

  const handleClickSound = (event, pronunciationId) => {
    if (isIOS()) {
      pronunciationStore.getPronunciationAudio(authStore.loginUser.id, userStore.selectedDevice.deviceId, pronunciationId)
          .then(() => {
            if (pronunciationStore.audio !== null) {
              pronunciationStore.audio.addEventListener('ended', function() {
                pronunciationStore.audio = null;
              });

              pronunciationStore.audio.play()
                  .then(() => {
                    console.log('Audio is playing.');
                  })
                  .catch((e) => {
                    console.error('Audio play failed:', e);
                  });
            }
          })
          .catch((e) => {
            console.error('Failed to fetch pronunciation audio URL:', e);
          });
    } else {
      pronunciationStore.getPronunciationAudioUri(authStore.loginUser.id, userStore.selectedDevice.deviceId, pronunciationId)
          .then(() => {
            if (pronunciationStore.audio !== null) {
              pronunciationStore.audio.addEventListener('ended', function() {
                pronunciationStore.audio = null;
              });

              pronunciationStore.audio.play()
                  .then(() => {
                    console.log('Audio is playing.');
                  })
                  .catch((e) => {
                    console.error('Audio play failed:', e);
                  });
            }
          })
          .catch((e) => {
            console.error('Failed to fetch pronunciation audio URL:', e);
          });
    }

  };

  return (
    <>
      <WrapBox>
        <ListenPronunciation>
          <div className="Title">
            <Typography variant="body1" sx={{color: '#909195', fontWeight:'bold'}}>
              {intl.formatMessage({ id: "listen_to_childs_pronunciation" })} {/* 아이 발음 듣기 */}
              </Typography>
            <Button className="sound-button" sx={{ minWidth: 'unset', padding: 0 }} onClick={(event) => handleClickSound(event, pronunciation.pronunciationId)}>
              <Icon icon={pronunciationStore.audio !== null ? "emojione:stop-button" : "emojione:play-button"} fontSize={32} />
            </Button>
          </div>
          <WordListBox mt={1} >
            {wordList.map((word, index) => (
                <WordList key={index} mr={2.5}>
                  <Typography variant="body1" color={word.score <= 45 ? "#fe536b": null}>{word.word}</Typography>
                  <Typography variant="caption">
                  {word.phonemeList.map((phoneme, index) => (
                      <span key={index}>
                        <span style={{ color: phoneme.score <= 45 ? "#fe536b" : null }}>{phoneme.phoneme}</span>
                        {index !== word.phonemeList.length - 1 && "・"}
                      </span>
                  ))}
                  </Typography>
                  <Typography variant="caption" style={{ display: "block" }}>
                    {word.phonemeList.map((phoneme, index) => (
                        <span key={index}>
                          <span style={{ color: phoneme.score <= 45 ? "#fe536b" : null }}>{phoneme.score}</span>
                          {index !== word.phonemeList.length - 1 && "・"}
                        </span>
                    ))}
                  </Typography>
                </WordList>
            ))}
          </WordListBox>
        </ListenPronunciation>

        <Stack
          spacing={1}
          sx={{width: '100%'}}
        >
          <Typography variant="body1" sx={{color: '#909195', fontWeight:'bold'}}>{intl.formatMessage({ id: "evaluation" })}</Typography>
          <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
            <Stack
              direction="row"
              spacing={3}
              sx={{
                alignItems: "center",
              }}
            >
             
              <div style={{ width: 100, textAlign:'center' }}>
                <PronuncuationCircularProgressbar value={value.accuracyScore} text={`${value.accuracyScore}`} pathColor="#fe536b"/>
                <Typography variant="body2" mt={0.5}>{intl.formatMessage({ id: "pronunciation_accuracy" })}</Typography>
              </div>
              <div style={{ width: 100, textAlign:'center' }}>
                <PronuncuationCircularProgressbar value={value.completenessScore} text={`${value.completenessScore}`} pathColor="#FF993C"/>
                <Typography variant="body2" mt={0.5}>{intl.formatMessage({ id: "intelligibility" })}</Typography>
              </div>
              
            </Stack>
            <Stack
              direction="row"
              spacing={3}
              sx={{
                alignItems: "center",
              }}
            >
              <div style={{width: 100, textAlign:'center' }}>
                <PronuncuationCircularProgressbar value={value.fluencyScore} text={`${value.fluencyScore}`} pathColor="#868AFF"/>
                <Typography variant="body2" mt={0.5}>{intl.formatMessage({ id: "prosody_fluency_card" })}</Typography>
              </div>
              <div style={{width: 100, textAlign:'center' }}>
                <PronuncuationCircularProgressbar value={value.pronScore} text={`${value.pronScore}`} pathColor="#ACDE5F"/>
                <Typography variant="body2" mt={0.5}>{intl.formatMessage({ id: "total_score" })}</Typography>
              </div>
            </Stack>
          </Box>
        </Stack>
      </WrapBox>
    </>
  );
}

export default injectIntl(inject('authStore', 'userStore', 'pronunciationStore')(observer(PronunciationListDetailComponent)));
